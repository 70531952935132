import { useState } from "react";

import { FormGroup, Input, Label } from "reactstrap";

import { Link } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";

import { useTranslation } from "react-i18next";

import { useGoogleLogin } from "@react-oauth/google";

import GoogleButton from "../../Button/GoogleButton";
import { FLOW_AUTHCODE } from "../../../constants/GOOGLE";
import HTMLReactParser from "html-react-parser";

const SignInForm = ({ onSignIn = () => { }, onSubmitGoogle = () => { }}) => {
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");

    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const onGoogleSignIn = useGoogleLogin(FLOW_AUTHCODE({onSubmitGoogle}));

    const linkTags = {
        initial_tag: "<a href='https://conia.uca.edu.sv/terms_conditions.html' className='text-blue-700 visited:text-purple-700' target='_blank'>",
        second_tag: "<a href='https://conia.uca.edu.sv/privacy_policy.html' className='text-blue-700 visited:text-purple-700' target='_blank'>",
        close_tag: "</a>"
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (!identifier || !password) {
            openSnack(t("snack_warn_fill_fields"), 3000);
            return;
        }

        onSignIn(identifier, password);
    }

    return (
        <>
            <form onSubmit={onSubmitForm} className="min-w-0 flex flex-col justify-center p-2 gap-2">

                <h2 className="font-bold text-2xl text-center"> { t("auth_si_title") } </h2>

                <FormGroup className="flex flex-col gap-4 mt-8">
                    <Label className="font-bold" for="login-identifier"> { `${t("auth_form_code")} / ${t("auth_form_email")}` } </Label>
                    <Input
                        className="min-h-10 px-4 rounded-md"
                        id="login-identifier"
                        name="identifier"
                        placeholder={ t("auth_form_email_eg") }
                        value={identifier}
                        onChange={({ target }) => { setIdentifier(target.value) }}
                        type="email" />
                </FormGroup>

                <FormGroup className="flex flex-col gap-4 mb-8">
                    <Label className="font-bold" for="login-password"> { t("auth_form_pass") } </Label>
                    <Input
                        className="min-h-10 px-4 rounded-md"
                        id="login-password"
                        name="password"
                        value={password}
                        placeholder="••••••••••••••••"
                        onChange={({ target }) => { setPassword(target.value) }}
                        type="password" />
                </FormGroup>

                <button type="submit" className="p-2 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-700">
                    { t("auth_form_si_btn") }
                </button>

                <GoogleButton onGoogleSignIn={onGoogleSignIn}/>
            </form>

            <div>
                <p className="mt-2 ml-2 mt-4 mb-1 text-pretty pr-px text-base leading-4 text-gray-600">
                    { HTMLReactParser(t("terms_policies_login_text", {initial_tag:  linkTags.initial_tag, close_tag: linkTags.close_tag, second_tag: linkTags.second_tag})) }
                </p>
            </div>

            <div className="p-2 flex flex-col gap-2 m-0">
                <p className="text-lg text-gray-600 m-0">
                    { t("auth_info_register_text") } <Link to="/auth/signup" className="text-primary underline underline-offset-4 transition-all ease-in-out duration-300 hover:text-primary-700"> { t("auth_info_register_link") } </Link>
                </p>

                <p className="text-lg text-gray-600">
                    { t("auth_info_forgot_text") } <Link to="/auth/forgot-password" className="text-primary underline underline-offset-4 transition-all ease-in-out duration-300 hover:text-primary-700"> { t("auth_info_forgot_link") } </Link>
                </p>
            </div>
        </>
    );
}

export default SignInForm;