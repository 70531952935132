import { useCallback, useEffect, useState } from "react";
import ROLES from "./../../constants/ROLES";

import { MdBookmark, MdBookmarks, MdEvent } from "react-icons/md";
import { FaUserFriends, FaTicketAlt } from "react-icons/fa";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PrivatePage from "../../wrappers/PrivatePage/PrivatePage";

import {
  useNavigate,
  useLocation,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import LandingPage from "./User/LandingPage/LandingPage";
import RequestProfilePage from "./RequestProfilePage/RequestProfilePage";
import ChooserPage from "./Attend/ChooserPage/ChooserPage";
import AttendReaderPage from "./Attend/AttendReaderPage/AttendReaderPage";

import axios from "axios";

import { useUserContext } from "../../contexts/UserContext";
import { useConfigContext } from "../../contexts/ConfigContext";
import { useInfoContext } from "../../contexts/InfoContext";
import { useSnackbar } from "react-simple-snackbar/";
import { useTranslation } from "react-i18next";
import MyPresentationPage from "./User/MyPresentationsPage/MyPresentationsPage";
import CareerPage from "../MainRouterPage/CareerPage/CareerPage";
import { getTranslation, mapToLanguages } from "../../services/translation";
import AttendantSelf from "./AttendantSelf/AttendantSelf";
import MyAssistent from "./MyAssistent/MyAssistent";
import ObtainQr from "./ObtainQr/ObtainQr";

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const AppPage = () => {
  const { token, user, whoami } = useUserContext();
  const { startLoading, stopLoading, lang } = useConfigContext();
  const { careers } = useInfoContext();
  const [openSnack] = useSnackbar();
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [myPresentations, setMyPresentations] = useState([]);

  useEffect(() => {
    const fetchPresentations = async () => {
      startLoading("fetch-my-presentations");
      try {
        if (token) {
          const { data } = await axios.get(`/user/presentation/${EVENT_ID}`);

          data.virtualList.data.sort((p1, p2) =>
            p1.datetime.localeCompare(p2.datetime)
          );

          data.onCampusList.data.sort((p1, p2) =>
            p1.datetime.localeCompare(p2.datetime)
          );

          setMyPresentations([
            ...data.virtualList.data
              .map((p) => mapToLanguages(p, "name"))
              .map((p) => {
                return {
                  ...p,
                  virtual: p.virtualLink ? true : false,
                };
              }),
            ...data.onCampusList.data
              .map((p) => mapToLanguages(p, "name"))
              .map((p) => {
                return {
                  ...p,
                  virtual: p.virtualLink ? true : false,
                };
              }),
          ]);
        }
      } catch (error) {
        openSnack(t("snack_error_fetch"));
        setMyPresentations([]);
      } finally {
        stopLoading("fetch-my-presentations");
      }
    };

    fetchPresentations();
  }, [token]);

  const allPages = [
    {
      id: "attendant-self-page",
      title: t("header_attendant_self_text"),
      icon: <MdEvent />,
      url: "/attend/self/:presentationId",
      role: ROLES.USER,
      hidden: true,
      disabled: false,
      component: <AttendantSelf />,
    },
    {
      id: "my-schedule-app-page",
      title: t("header_my_schedule_text"),
      icon: <MdEvent />,
      url: "/my-schedule",
      role: ROLES.USER,
      disabled: false,
      component: <MyPresentationPage />,
    },
    {
      id: "careers-pages",
      title: t("header_careers_text"),
      icon: <MdBookmarks />,
      role: ROLES.USER,
      pages: careers.map((career) => ({
        id: `${career.code}_page`,
        title: getTranslation(career, "name", lang),
        icon: <MdBookmark />,
        loginReq: false,
        role: ROLES.USER,
        disabled: false,
        component: <CareerPage />,
        url: `/career/${career.uri}`,
      })),
    },
    {
      id: "attend-app-pages",
      title: "Asistencia",
      icon: <FaUserFriends />,
      role: ROLES.ATTEND,
      pages: [
        {
          id: "attend-list-app-page",
          title: "Registrar Asistencia",
          icon: <FaTicketAlt />,
          url: "/attend/list",
          role: ROLES.ATTEND,
          disabled: false,
          component: <ChooserPage />,
        },
        {
          id: "attend-reader-app-page",
          title: "Lector",
          icon: <FaTicketAlt />,
          url: "/attend/reader/",
          hidden: true,
          role: ROLES.ATTEND,
          disabled: false,
          component: <AttendReaderPage />,
        },
        {
          id: "obtain-qr-app-page",
          title: t("obtain_qr_text"),
          icon: <FaTicketAlt />,
          url: "/attend/qr",
          role: ROLES.ATTEND,
          disabled: false,
          hidden: true,
          component: <ObtainQr />,
        },
      ],
    },
    {
      id: "my-assistances-app-page",
      title: t("header_attendant_self_text"),
      icon: <FaUserFriends />,
      url: "/my-assistances",
      role: ROLES.USER,
      disabled: false,
      component: <MyAssistent />,
    },
  ];

  useEffect(() => {
    if (!token) {
      navigate("/auth/signin", {
        state: pathname,
      });
    }
  }, [token, navigate, pathname]);

  const subscribe = useCallback(
    (unsubs = false, onSuccess) => {
      const subscribeAsync = async () => {
        startLoading("subscribing-main");
        try {
          await axios.post(`/event/${unsubs ? "unsubscribe" : "subscribe"}`, {
            event: EVENT_ID,
          });
          onSuccess();
        } catch (error) {
          if (error.response) {
            switch (error.response.status) {
              case 409:
                openSnack(
                  unsubs
                    ? t("snack_error_isnt_registered")
                    : t("snack_error_already_registered")
                );
                break;
              case 400:
                openSnack(t("snack_error_wrong_fields"));
                break;
              default:
                openSnack(t("snack_error_unexpected"));
                break;
            }
          }
        } finally {
          stopLoading("subscribing-main");
        }
      };

      subscribeAsync();
    },
    [openSnack, startLoading, stopLoading, t]
  );

  const onSubscribe = async (presentationId) => {
    startLoading("subs-to-pres");

    try {
      await axios.delete(`/user/presentation/${presentationId}`, {
        data: {
          virtual: myPresentations.find((p) => p._id === presentationId)
            .virtual,
        },
      });
      setMyPresentations(
        myPresentations.filter((p) => p._id !== presentationId)
      );
    } catch (error) {
      openSnack(t("snack_error_unexpected"));
    } finally {
      stopLoading("subs-to-pres");
    }
  };

  const pagesLinks = user
    ? allPages.filter((page) => {
        const index = user.roles.findIndex(
          (role) => role === page.role || role === ROLES.SYSADMIN
        );
        return index >= 0;
      })
    : [];

  const flatRoutes = (pages = [], role = "") => {
    return pages.reduce((acc, page) => {
      return page.pages
        ? [...acc, ...flatRoutes(page.pages, page.role)]
        : [
            ...acc,
            <Route
              key={page.id}
              path={page.url}
              element={
                <PrivatePage role={page.role ?? role}>
                  {page.component}
                </PrivatePage>
              }
            />,
          ];
    }, []);
  };

  const routesToRender = (
    <Routes>
      {flatRoutes(allPages)}

      <Route
        index
        element={
          user && user.role === ROLES.ADMIN ? (
            <Navigate to="/app/admin/events" />
          ) : (
            <PrivatePage>
              <LandingPage
                onSubscribe={onSubscribe}
                myPresentations={myPresentations}
              />
            </PrivatePage>
          )
        }
      />
    </Routes>
  );

  const profileFillForm = (
    <RequestProfilePage
      subscribeOnFinish={() => {
        subscribe(false, whoami);
      }}
    />
  );

  const content = user ? (
    user.requestProfile ? (
      profileFillForm
    ) : (
      routesToRender
    )
  ) : (
    <></>
  );

  return (
    <>
      <Header pages={pagesLinks} concatPath="/app" eventID={EVENT_ID} />
      {content}
      {/*<Footer showLogos={false} />*/}
    </>
  );
};

export default AppPage;
