import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

const EjesTematicos = ({
  page,
  activeMenu,
  setActiveMenu,
  optionsGeneration,
}) => {
  const isDesplegable = activeMenu === page.id;

  const handleClick = () => {
    setActiveMenu(isDesplegable ? null : page.id); // Alterna el menú actual
  };

  return (
    <div
      key={`nav-${page.id}`}
      className="relative group flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center text-text-md"
    >
      <button
        onClick={handleClick}
        className="text-on-nav-color flex items-center justify-start lg:justify-center gap-2"
      >
        <div className="text-accent-color-secondary">{page.icon}</div>
        <div className="flex items-center gap-0">
          {page.title}
          {isDesplegable ? (
            <MdArrowDropUp className="text-2xl" />
          ) : (
            <MdArrowDropDown className="text-2xl" />
          )}
        </div>
      </button>

      <ul
        className={`lg:absolute ${
          isDesplegable ? "flex" : "hidden"
        } flex-col justify-start items-start pl-4 m-0 gap-2 pt-2 bg-nav-color lg:gap-4 lg:pl-8 lg:pr-8 lg:pt-4 lg:pb-4 lg:top-full lg:left-0 lg:w-80`}
      >
        {page.pages.map(optionsGeneration)}
      </ul>
    </div>
  );
};

export default EjesTematicos;
