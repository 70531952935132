import { useEffect } from "react";

import ROLES from '../../constants/ROLES';

import { useNavigate, useLocation } from 'react-router-dom';
import { useUserContext } from "../../contexts/UserContext";

const PrivatePage = ({ children, role=ROLES.USER }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();

    useEffect(()=> {
        if(!user || user.roles.findIndex(uRole => (uRole === role || uRole === ROLES.SYSADMIN)) < 0) {
            navigate("/not-found");
        }
    }, [user]);

    return <>
        { children }
    </>
    
}

export default PrivatePage;