export const mapToLanguages = (obj, ...attrs) => {
  const result = {
    ...obj,
  };

  attrs.forEach((key) => {
    const texts = obj[key] || [];
    const newAttr = {};
    texts.forEach((text) => {
      newAttr[text.lang] = text.value;
    });

    result[key] = { ...newAttr };
  });

  return result;
};

export const getTranslation = (obj, key, lang) => {
  return (
    obj[key]?.[lang] || obj[key]?.[Object.keys(obj[key])[0]] || ""
  );
};
