import { NavLink } from "react-router-dom";

const MenuOption = ({ page, concatPath, setActiveMenu }) => {
  return !page.hidden ? (
    <li key={`nav-${page.id}`} className="nav-item flex items-start gap-12 justify-start"
        onClick={() => setActiveMenu(null)}>
      <NavLink
        to={concatPath + page.url}
        className="text-on-nav-color flex flex-row items-center justify-start no-underline gap-2 hover:text-accent">
        <div className="text-accent-color-secondary">{page.icon}</div>
        {page.title}
      </NavLink>
    </li>
  ) : null;
};

export default MenuOption;
