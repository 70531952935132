import React, { useState, useMemo, useEffect } from "react";
import TableSearch from "./TableSearch";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import Pagination from "../Pagination/Pagination";

const Table = ({
  columns,
  data,
  handleSort,
  sortConfig,
  sortableColumns = [],
  filterableColumns = [],
  searchColumn,
  setSearchColumn,
  searchTerm,
  setSearchTerm,
  itemsPerPage,
}) => {
  const [filterColumns, setFilterColumns] = useState([columns[0]]);
  const [currentPage, setCurrentPage] = useState(1);

  // Filtrar los datos basados en el término de búsqueda y las columnas seleccionadas
  const filteredData = useMemo(() => {
    if (!searchTerm || filterColumns.length === 0) {
      return data;
    }
    return data.filter((row) =>
      filterColumns.some((col) => {
        return row[col]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
  }, [data, searchTerm, filterColumns]);

  // Calcular el número total de páginas basado en los datos filtrados
  const totalPages = useMemo(() => {
    return Math.ceil(filteredData.length / itemsPerPage);
  }, [filteredData.length, itemsPerPage]);

  // Verificar que currentPage no exceda el total de páginas
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterColumns, searchTerm]);

  // Reiniciar la página cuando se actualizan los datos
  useEffect(() => {
    // Solo inicializar los filtros si no hay filtros seleccionados
    if (filterColumns.length === 0 && columns.length > 0) {
      setFilterColumns([columns[0]]);
    }
  }, [columns]);

  // Calcular los datos que se mostrarán en la página actual (paginación aplicada después del filtro)
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, itemsPerPage, filteredData]);

  return (
    <div className="grid grid-cols-1 grid-rows-[40px_500px_60px] gap-4">
      {/* Componente de búsqueda */}
      <TableSearch
        columns={filterableColumns.length > 0 ? filterableColumns : columns}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filterColumns={filterColumns}
        setFilterColumns={setFilterColumns}
      />

      {/* Tabla */}
      <div className="row-start-2 row-end-3 col-start-1 col-end-2 overflow-x-auto">
        <table className="w-full text-left rtl:text-right">
          <TableHeader
            columns={columns}
            sortableColumns={
              sortableColumns.length > 0 ? sortableColumns : columns
            }
            handleSort={handleSort}
            sortConfig={sortConfig}
          />
          <TableBody data={paginatedData} columns={columns} />
        </table>
      </div>

      {/* Paginación */}
      {filteredData.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};

export default Table;
