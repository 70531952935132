import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

import MainRouterPage from "./pages/MainRouterPage/MainRouterPage";
import AuthPage from './pages/AuthPage/AuthPage';
import AppPage from './pages/AppPage/AppPage';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import { Helmet } from "react-helmet";
import { useConfigContext } from "./contexts/ConfigContext";

function App() {
  const { lang } = useConfigContext();

  return (
    <div className="font-sans flex flex-col h-svh">
      <Helmet htmlAttributes={{lang}}/>
      <Router basename={process.env.REACT_APP_BASEPATH}>
        <ScrollToTop/>
        <Routes>
          <Route path="/auth/*" element={ <AuthPage/> }/>

          <Route path="/app/*" element={ <AppPage/> }/>

          <Route path="/*" element={<MainRouterPage/>} />          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
