import { useTranslation } from "react-i18next";
import ButtonInscription from "../Button/ButtonInscription"

const InternalCard = (
    {
        title,
        info = [],
        color = "bg-white",
        roundedCorner,
        ppx = "px-0",
        ppy = "py-11",
        overflow,
        z = "z-0",
        justify = "justify-center"
    }
) =>{
    return(
        <div className={`flex flex-col w-1/2 max-lg:w-full pt-4 ${color} ${roundedCorner} px-2 overflow-hidden ${z} shadow-[1px_0px_50px_0px_rgba(0,0,0,0.3)]`}>
            <h2 className="text-center text-text-color pb-2">
                {title}
            </h2>
            <hr className="border-t-2 rounded-full border-secondary-300"/>

            <div className={`flex ${justify} flex-col w-full ${ppx} ${ppy} h-full ${ppy} overflow-hidden`}>
                {info.map((infoUsed, index) => (
                    <p className={`text-center text-text-color w-full break-words leading-5 ${overflow} scrollbar`} key={index} > 
                        {infoUsed}
                    </p>
                ))}
            </div>
        </div>  
    );
}

const CeremonyCard = (
    {
        id,
        date,
        hour,
        description,
        ceremonyName,
        location,
        myPresentations,
        presentation,
        onSubscribe = (id) => {
            console.log(`Inscribirse a la presentación con ID: ${id}`);
        } 
      }
) =>{
    const { t: translate } = useTranslation();
    
    return(
        <div className="flex w-full h-full flex-col rounded-lg overflow-hidden isolate bg-white shadow-[4px_4px_30px_-0px_rgba(0,0,0,0.3)]">
            <div className="w-full bg-primary-400 p-4 shadow-[0_5px_30px_0px_rgba(0,0,0,0.3)] z-[2]">
                <h1 className="text-center text-white text-[1.4rem] overflow-hidden whitespace-nowrap text-ellipsis" title={ceremonyName}>
                    {ceremonyName}
                </h1>
            </div>

            <div className="flex w-full h-full lg:max-h-[200px] flex-row max-lg:flex-col">
                <InternalCard title = {translate("ceremony_location_schedule")} info = {[location, date, hour]} 
                    color = {"bg-primary-200"} roundedCorner = {"max-lg:rounded-b-lg lg:rounded-r-lg"} z={"z-[1]"}
                />

                <InternalCard title = {translate("ceremony_description")} info = {[description]} 
                    roundedCorner = {"rounded-none"} ppx={"px-4"} ppy={"py-5"} overflow={"overflow-auto"} justify="justify-start"
                />
            </div>
            
            <ButtonInscription className="px-4 py-[0.6rem] text-lg text-white font-bold z-[2] hover:brightness-110 hover:opacity-90 transition duration-300 ease-in" active={true} myPresentations={myPresentations} onSubscribe={() => onSubscribe(id)} presentation={presentation}/>
        </div>
    );
};

export default CeremonyCard;