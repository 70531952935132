import { useTranslation } from "react-i18next";
import html from "html-react-parser";
import constants from "../../constants/API";
import SocialMedia from "../SocialMedia/SocialMedia";

const Footer = ({ pages = [] }) => {
  const { t: translate } = useTranslation();

  const HistoryData = [
    {
      year: "2019",
      link: "https://conia.uca.edu.sv/memorias/files/revista_conia_2019.pdf",
    },
    {
      year: "2018",
      link: "https://conia.uca.edu.sv/memorias/files/revista_conia_2018.pdf",
    },
    {
      year: "2015",
      link: "https://conia.uca.edu.sv/memorias/files/revista_conia_2015.pdf",
    },
    {
      year: "2014",
      link: "https://conia.uca.edu.sv/memorias/files/revista_conia_2014.pdf",
    },
  ];

  const HistoryList = HistoryData.map((item, index) => (
    <li key={index}>
      <a
        className="py-2 text-on-nav-color no-underline hover:text-accent"
        type="button"
        download=""
        href={item.link}
      >
        {translate(`CONIA ${item.year}`)}
      </a>
    </li>
  ));

  const EjesTematicos = (pages) => {
    return pages.map((items) => {
      if (items.pages) {
        return items.pages.map((item, index) => {
          return (
            <li key={index}>
              <a
                className="py-2 text-on-nav-color no-underline hover:text-accent"
                href={item.url}
              >
                {html(item.title)}
              </a>
            </li>
          );
        });
      }
    });
  };

  const TitleClass = "text-2xl lg:text-3xl font-bold capitalize";
  const ItemClass =
    "flex flex-col text-on-nav-color no-underline hover:text-accent-color-secondary gap-2 mt-8";

  return (
    <footer className="flex flex-col gap-3 py-8 bg-secondary text-primary-on-primary items-center w-full">
      <div className="flex flex-col gap-2 justify-center items-start w-fit md:flex-row md:justify-around md:w-full">
        <div className={`${ItemClass} !gap-0 max-w-[250px]`}>
          <a href="https://uca.edu.sv" target="_blank" rel="noreferrer">
            <img
              src={`${constants.FILE_BASEURL}/LogotipoCblanco.png`}
              alt="Logo UCA"
              className="relative h-auto max-h-20 lg:max-h-28 -left-3"
            />
          </a>
          <address className="mt-2">
            Bulevar Los Próceres <br /> Antiguo Cuscatlán <br /> La Libertad, El
            Salvador <br />
            Centroamérica.
          </address>
          <a
            href="tel:+2210-6000"
            className=" text-text-color-logo-inferior font-bold decoration-transparent text-left w-[70%]"
          >
            <i className="fa fa-phone"></i> 2210-6600 ext. 380
          </a>
          <a
            href="mailto:conia@uca.edu.sv"
            className=" text-text-color-logo-inferior font-bold decoration-transparent text-left w-[70%]"
          >
            <i className="fa fa-envelope"></i> conia@uca.edu.sv
          </a>
        </div>
        <div className={ItemClass}>
          <h4 className={TitleClass}>{translate("header_historico")}</h4>
          <ul className="flex flex-col p-0 m-0 text-lg">{HistoryList}</ul>
        </div>
        <div className={ItemClass}>
          <h4 className={TitleClass}>{translate("header_socials_text")}</h4>
          <SocialMedia
            className="flex flex-col"
            nameClass="flex justify-center text-center p-0 m-0 text-on-nav-color no-underline !text-lg hover:text-accent"
            iconClass="p-0 text-accent-color-secondary border-none no-underline !text-lg hover:bg-opacity-0"
          />
        </div>
        <div className={ItemClass}>
          <h4 className={TitleClass}>{translate("header_careers_text")}</h4>
          <ul className="flex flex-col text-lg">{EjesTematicos(pages)}</ul>
        </div>
      </div>
      <div className="flex gap-2 py-2 justify-center">
        <p className="text-on-nav-color hover:text-accent text-center">
          <a href="https://conia.uca.edu.sv/terms_conditions.html" > { translate("terms_footer_text") } </a>
        </p>
        <span className="text-on-nav-color">
          ·
        </span>
        <p className="text-on-nav-color hover:text-accent text-center">
          <a href="https://conia.uca.edu.sv/privacy_policy.html" target="_blank"> { translate("privacy_footer_text") } </a>
        </p>
      </div>
      <div>
        <h4 className="text-center text-text-md text-on-nav-color">
          Copyright © CONIA 2024.
        </h4>
      </div>
    </footer>
  );
};

export default Footer;
