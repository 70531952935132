import HTMLReactParser from "html-react-parser";
import SocialMedia from "../../../components/SocialMedia/SocialMedia";
import TimelineComponent from "../../../components/Timeline/Timeline";
import html from "html-react-parser";
import { useTranslation } from "react-i18next";

const TimelineAboutComponent = ({ dates, lang = "es" }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
        {HTMLReactParser(translate("index_timeline_text"))}
      </h2>
      <div>
        <TimelineComponent dates={dates} lang={lang} />
      </div>
    </div>
  );
};

export default TimelineAboutComponent;
