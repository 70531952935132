import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

const ConfigContext = React.createContext();
const LANGKEY = "context_lang_key";

export const ConfigProvider = (props) => {
    const [loading, setLoading] = useState(false);
    const [autoSubscribe, setAutoSubscribe] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState([]);
    const [lang, setLang] = useState(getLangLS());
    

    const { i18n } = useTranslation();

    const startLoading = (id) => {
        if(!id) return; 

        const dataSet = new Set(loadingQueue);
        dataSet.add(id);

        setLoadingQueue(Array.from(dataSet));
    }

    const stopLoading = (id) => {
        if(!id) return; 

        let dataSet = [...loadingQueue];
        dataSet = dataSet.filter(value => value !== id);

        setLoadingQueue(dataSet);
    }

    const toggleLang = useCallback(() => {
        setLang(lang === "es" ? "en" : "es");
    }, [lang]);

    useEffect(() => {
        setLoading(loadingQueue.length > 0)
    }, [loadingQueue])

    useEffect(() => {
        i18n.changeLanguage(lang);
        setLangLS(lang);
    }, [lang]);
    
    const state = useMemo(()=> ({
        loading, startLoading, stopLoading, toggleLang, autoSubscribe, setAutoSubscribe, lang, setLang
    }), [loading, autoSubscribe, lang]);

    return <ConfigContext.Provider value={state} {...props} />
}

export const useConfigContext = () => {
    const context = React.useContext(ConfigContext);

    if(!context) {
        throw new Error("useConfigContext must be call inside of a ConfigContextProvider component");
    }

    return context;
}

const getLangLS = () => localStorage.getItem(LANGKEY) || "es";
const setLangLS = (lang = "es") => localStorage.setItem(LANGKEY, lang);
const removeLangLS = () => localStorage.removeItem(LANGKEY);