import TableRow from "./TableRow";

const TableBody = ({ data, columns }) => {
  return (
    <tbody>
      {data.map((rowData, index) => (
        <TableRow key={index} rowData={rowData} columns={columns} />
      ))}
    </tbody>
  );
};

export default TableBody;
