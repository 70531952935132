/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

import { useTranslation } from 'react-i18next';

const TOKEN_KEY = "token";

const PasswordRecoveryForm = ({ onPasswordRecovery }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [token, setToken] = useState(undefined);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const _token = Object.fromEntries([...searchParams])[TOKEN_KEY];

    if (!_token) {
      navigate("/auth/signin")
    }

    setToken(_token);
  }, [searchParams]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    onPasswordRecovery(token, password);
  }

  return (
    <>
      <form onSubmit={onSubmitForm} className='min-w-0 flex flex-col justify-center p-2 gap-2'>
        <h2 className="font-bold text-2xl text-center" > { t("auth_pr_title") } </h2>

        <FormGroup className="flex flex-col gap-4 mt-8">
          <Label className="font-bold" for="login-password"> { t("auth_form_pass") } </Label>
          <Input
            className="min-h-10 px-4 rounded-md"
            id="login-password"
            name="password"
            value={password}
            onChange={({ target }) => { setPassword(target.value) }}
            type="password" />
          <FormText> { t("auth_form_password_regex") } </FormText>
        </FormGroup>

        <button type="submit" className="p-2 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-700">
        { t("auth_form_restore_pass_btn") }
        </button>
      </form>
    </>
  );
}

export default PasswordRecoveryForm;