import { useState } from "react";

import { FormGroup, Input, Label, FormText } from "reactstrap";

import { Link, redirect } from "react-router-dom";

import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from 'react-i18next';

import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { useGoogleLogin } from "@react-oauth/google";

import GoogleButton from "../../Button/GoogleButton";
import { FLOW_AUTHCODE } from "../../../constants/GOOGLE";
import CheckTerms from "../../CheckTerms/CheckTerms";

const SignUpForm = ({ onSignUp = () => { }, onSubmitGoogle = () => { }}) => {
    const [identifier, setIdentifier] = useState("");
    const [password, setPassword] = useState("");
    const [check, setChecked] = useState(false);

    const [openSnack] = useSnackbar();
    const { t } = useTranslation();
    
    const onGoogleSignIn = useGoogleLogin(FLOW_AUTHCODE({onSubmitGoogle}));
    
    const checkIsEnable = () => {
        if (!check) {
            openSnack(t("snack_warn_accept_terms"), 5000);
        } else {
            onGoogleSignIn();
        }
    }


    const handleChecked = (status) => {
        setChecked(status);
    }

    const onSubmitForm = (e) => {
        e.preventDefault();

        if (!identifier || !password) {
            openSnack(t("snack_warn_fill_fields"), 3000);
            return;
        }

        if (!check) {
            openSnack(t("snack_warn_accept_terms"), 5000);
            return;
        }

        onSignUp(identifier, password);
    }

    return (
        <>
            <form onSubmit={onSubmitForm} className="min-w-0 flex flex-col justify-center p-2 gap-2">

                <div className="flex items-center">
                    <Link className="cursor-pointer text-2xl text-primary" to="/auth/signin"> <IoArrowBackCircleSharp /> </Link>
                    <h2 className="!mb-0 flex-1 font-bold text-2xl text-center" > { t("auth_su_title") } </h2>
                </div>

                <FormGroup className="flex flex-col gap-4 mt-4">
                    <Label className="font-bold" for="login-identifier"> { t("auth_form_email") } </Label>
                    <Input
                        className="min-h-10 px-4 rounded-md"
                        id="login-identifier"
                        name="identifier"
                        placeholder={ t("auth_form_email_eg") }
                        value={identifier}
                        onChange={({ target }) => { setIdentifier(target.value) }}
                        type="email" />
                </FormGroup>

                <FormGroup className="flex flex-col gap-4">
                    <Label className="font-bold" for="login-password"> { t("auth_form_pass") } </Label>
                    <Input
                        className="min-h-10 px-4 rounded-md"
                        id="login-password"
                        name="password"
                        value={password}
                        placeholder="••••••••••••••••"
                        onChange={({ target }) => { setPassword(target.value) }}
                        type="password" />
                    <FormText> { t("auth_form_password_regex") } </FormText>
                </FormGroup>

                <FormGroup>
                    <CheckTerms checked={!check} onChange={handleChecked}/>
                </FormGroup>

                <button type="submit" className="p-2 bg-primary text-white font-semibold rounded-md shadow-md hover:bg-primary-700">
                { t("auth_form_su_btn") }
                </button>

                <GoogleButton onGoogleSignIn={checkIsEnable}/>
            </form>
        </>
    );
}

export default SignUpForm;