import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TiSocialLinkedin, TiSocialLinkedinCircular } from "react-icons/ti";

import axios from "axios";
import Slider from "../../../../components/Slider/Slider";
import { getTranslation } from "../../../../services/translation";

const Speakers = ({ lang = "es", masterTalks = [], slidesToShow = 1 }) => {
  const { t: translate } = useTranslation();

  return masterTalks.length > 0 ? (
    <section className="flex-col items-center gap-4 w-[90%]">
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
        {translate("index_ponentes_text")}
      </h2>
      <Slider slidesToShow={slidesToShow} className="max-w-[85%] mt-5">
        {masterTalks.map((talk, index) => (
          <div
            key={talk._id}
            data-index={index}
            className="h-[550px] xs:h-[500px] md:h-[300px] shadow sm:flex bg-primary-800 border-primary-800 rounded-lg"
          >
            <a
              href={/*`/career/${Speakers.uri}`*/ "#"}
              className="md:w-[300px] md:min-w-[300px]"
            >
              <img
                className="w-full h-[300px] md:h-full object-cover rounded-lg sm:rounded-none sm:rounded-l-lg"
                src={talk.speakers[0].photo}
                alt={talk.speakers[0].name}
              />
            </a>
            <div className="flex flex-col gap-4 p-5">
              <a
                href={
                  talk.speakers[0].socials.length > 0
                    ? talk.speakers[0].socials[0].uri
                    : "#"
                }
                className="flex flex-col"
                target="_blank"
              >
                <h3 className="text-2xl font-bold tracking-tight text-white">
                  {talk.speakers[0].name}
                </h3>
                <p className="text-sm font-bold text-white opacity-70">
                  {talk.speakers[0].organization}
                </p>
              </a>
              <div className="text-white">
                <h4>{getTranslation(talk, "name", lang)}</h4>
              </div>
              {/* <ul className="flex space-x-4 sm:mt-0">
                <li>
                  <a href="#" className="text-gray-300 hover:text-white">
                    <TiSocialLinkedin className="w-5 h-5" />
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
        ))}
      </Slider>
    </section>
  ) : null;
};

export default Speakers;
