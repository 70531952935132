import axios from "axios";
import API from "./API";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from 'react-i18next';

const FLOW_AUTHCODE = ({onSubmitGoogle = () => { }}) => {
    const [openSnack] = useSnackbar();
    const { t : translate } = useTranslation();
    return {
        onSuccess: async ({ code: token }) => {
            const tokens = await axios.post(`/auth/google-login`, {
                token,
            });
            onSubmitGoogle(tokens.data);
        },
        onError: () => {
            openSnack(translate("snack_error_signin_google_fail"))
        },
        flow: 'auth-code',
    }
}

export { FLOW_AUTHCODE };