export function DateFormatter(schedule, lang){
    const date = new Date(schedule);

    const dateFromatted = date.toLocaleString(lang, {month: "long", day: "numeric", year: "numeric"});
    const weekday = date.toLocaleString(lang, {weekday: "long"});
    const weekdayFromatted = weekday.charAt(0).toUpperCase() + weekday.slice(1);

    return weekdayFromatted + " " + dateFromatted;
}
    
export function HourFromatter(schedule, lang){
    const date = new Date(schedule);

    return date.toLocaleString(lang, {hour12: false, hour: "numeric", minute: "2-digit"});
}