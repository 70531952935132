import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Navigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { toJpeg } from "html-to-image"; // Importar la función toJpeg
import { getTranslation } from "../../../services/translation";
import { useConfigContext } from "../../../contexts/ConfigContext";
import API from "../../../constants/API";
import { useTranslation } from "react-i18next";
const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

function InfoField({ label, value }) {
  return (
    <div className="flex gap-2">
      <span className="text-xl font-bold text-gray-600">{label}:</span>
      <span className="text-lg">{value}</span>
    </div>
  );
}

const ObtainQr = () => {
  const { state, pathname } = useLocation();
  const { lang } = useConfigContext();
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const qrRef = useRef(null); // Referencia al QR para capturar como imagen
  const logo_conia = `${API.FILE_BASEURL}/logo-${EVENT_ID}.webp`;

  useEffect(() => {
    if (!state) return;
    const fetchPresentationQr = async () => {
      try {
        const { data } = await axios.get(
          `/presentation/qr/${state._id}?expiredTime=30`
        );
        setToken(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPresentationQr();
  }, [state]);

  if (!state) {
    return <Navigate to={`/app/attend/list`} />;
  }

  // Calculamos la fecha de expiración basándonos en el "iat" y el "time"
  const expirationDate = new Date(
    new Date(token.iat).getTime() + token.expiredTime * 60000
  );

  // Función para descargar el QR como imagen
  const downloadQR = async () => {
    if (qrRef.current === null) {
      return;
    }

    toJpeg(qrRef.current, { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr_code.jpeg";
        link.click();
      })
      .catch((error) => {
        console.error("Error al generar la imagen", error);
      });
  };

  // Función para activar el modo de pantalla completa
  const enterFullScreen = () => {
    if (qrRef.current) {
      if (qrRef.current.requestFullscreen) {
        qrRef.current.requestFullscreen();
      } else if (qrRef.current.mozRequestFullScreen) {
        // Firefox
        qrRef.current.mozRequestFullScreen();
      } else if (qrRef.current.webkitRequestFullscreen) {
        // Chrome, Safari y Opera
        qrRef.current.webkitRequestFullscreen();
      } else if (qrRef.current.msRequestFullscreen) {
        // IE/Edge
        qrRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-4 gap-8">
      <div class="flex justify-center items-center gap-2">
        <button
          onClick={enterFullScreen}
          className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
        >
          {t("text_view_full_screen")}
        </button>
        <button
          onClick={downloadQR}
          className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          {t("download_qr_text")}
        </button>
      </div>
      <div
        ref={qrRef}
        className="relative grid w-10/12 grid-cols-2 grid-rows-1fr-2fr bg-white"
      >
        <svg
          className="absolute inset-0 w-full h-full"
          viewBox="0 0 300 500"
          preserveAspectRatio="none"
        >
          <polygon
            points="300,500 240,500 300,400"
            className="fill-green-500"
          />
          <polygon points="0,0 60,0 0,100" className="fill-green-500" />
          <polygon points="0,500 60,500 0,400" className="fill-black" />
          <polygon points="300,0 300,100 240,0" className="fill-black" />
        </svg>
        <div className="col-span-2 flex justify-center items-center">
          <img src={logo_conia} alt="logo conia" />
        </div>

        {/* Primer div que ocupa una columna en la segunda fila */}
        <div className="md:w-full rounded-l-lg shadow-lg overflow-hidden ">
          {/* Badge content */}
          <div className="relative flex flex-col items-center justify-center p-6 h-full">
            <h1 className="mb-4">{t("presentation_event_qr_subtitle")}</h1>

            {/* QR Code */}
            <div className="mb-8">
              {token.token && (
                <QRCode
                  size={256}
                  value={`${window.location.origin}/app/attend/self/${token.token}`}
                  level="H"
                />
              )}
            </div>

            {/* Mostrar la fecha de expiración */}
            <p className="text-xl mb-6 text-center">
              El código expira el:{" "}
              {expirationDate.toLocaleString("es-ES", {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </p>
          </div>
        </div>

        {/* Segundo div que ocupa una columna en la segunda fila */}
        <div className="relative md:w-full rounded-r-lg shadow-lg overflow-hidden">
          {/* Badge content */}
          <div className="relative flex flex-col justify-center p-6 pr-32 h-full">
            <h2 className="mb-4 text-2xl">
              {getTranslation(state, "name", lang) || "N/A"}
            </h2>
            <InfoField
              label={t("presentation_event_date")}
              value={new Date(state.datetime).toLocaleString("es-ES", {
                dateStyle: "medium",
              })}
            />
            <InfoField
              label={t("presentation_event_time")}
              value={new Date(state.datetime).toLocaleString("es-ES", {
                timeStyle: "short",
              })}
            />
            <InfoField
              label={t("presentation_event_place")}
              value={`${state.onCampusLocation.name}`}
            />
            <InfoField
              label={t("presentation_event_ponent")}
              value={`${state.speakers
                .map((speaker) => speaker.name)
                .join(", ")}`}
            />
          </div>
        </div>
      </div>

      {/* Botón para activar pantalla completa */}
    </div>
  );
};

export default ObtainQr;
