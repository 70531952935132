const Card = ({ children, className, ...rest }) => {
    return (
        <div className={`w-full min-w-0 max-w-3xl md:min-h-0 p-4
        flex flex-col
        md:rounded-md overflow-hidden 
        md:shadow-md ${className}`} {...rest}>
            { children }
        </div>
    );
}

export default Card;