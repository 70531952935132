const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const totalDisplay = 3; // Mostrar siempre 3 botones numéricos de página
  const pageNeighbors = 1; // Número de páginas visibles a cada lado de la actual

  // Función para generar el rango de páginas que se van a mostrar
  const renderPageNumbers = () => {
    const pages = [];

    // Mostrar siempre la primera página
    pages.push(1);

    // Determinar el rango de páginas cercanas a la página actual
    let startPage = Math.max(2, currentPage - pageNeighbors);
    let endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

    // Ajustar si estamos cerca del principio o del final
    if (currentPage <= pageNeighbors + 1) {
      endPage = Math.min(totalPages - 1, 1 + pageNeighbors * 2);
    } else if (currentPage >= totalPages - pageNeighbors) {
      startPage = Math.max(2, totalPages - (pageNeighbors * 2));
    }

    // Añadir las páginas del rango calculado
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Mostrar siempre la última página
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const handlePageChange = (event, page) => {
    event.preventDefault(); // Evitar el comportamiento predeterminado
    onPageChange(page);
  };

  const displayedPages = renderPageNumbers();

  return (
    <nav className="flex justify-between mt-4 w-full max-w-lg mx-auto">
      <ul className="flex items-center space-x-2">
        {/* Botón de página anterior */}
        <li>
          <button
            className={`w-10 h-10 px-2 py-2 text-center rounded-lg bg-primary-400 text-white border-primary-400 outline-none`}
            onClick={(event) => handlePageChange(event, currentPage - 1)}
            disabled={currentPage === 1} // Deshabilitar si es la primera página
          >
            &lt;
          </button>
        </li>
      </ul>

      <ul className="flex items-center space-x-2 justify-center">
        {/* Renderizar las páginas */}
        {displayedPages.map((page, index) => (
          <li key={index}>
            {page === "..." ? (
              <span className="w-10 h-10 flex items-center justify-center">...</span>
            ) : (
              <button
                className={`w-10 h-10 text-center rounded-lg border-white outline-none ${
                  currentPage === page ? "bg-accent border-accent" : ""
                }`}
                onClick={(event) => handlePageChange(event, page)}
              >
                {page}
              </button>
            )}
          </li>
        ))}
      </ul>

      <ul className="flex items-center space-x-2">
        {/* Botón de página siguiente */}
        <li>
          <button
            className={`w-10 h-10 px-2 py-2 text-center rounded-lg bg-primary-400 text-white outline-none border-primary-400`}
            onClick={(event) => handlePageChange(event, currentPage + 1)}
            disabled={currentPage === totalPages} // Deshabilitar si es la última página
          >
            &gt;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
