import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../services/translation";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import GenericTableWithPagination from "../../../../components/Table/GenericTableWithPagination";
import ButtonInscription from "../../../../components/Button/ButtonInscription";
import API from "../../../../constants/API";

const PresentationTable = ({
  presentations = [],
  myPresentations = [],
  areAllMine = false,
  columns = [],
  onSubscribe = (id) => {},
  itemsPerPage = parseInt(API.ITEMS_FOR_PAGE), // Controlamos cuántos elementos por página desde aquí
}) => {
  const { t } = useTranslation();
  const { lang } = useConfigContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchColumn, setSearchColumn] = useState("");

  const columnTranslations = {
    es: {
      name: "conferencia",
      speakers: "ponente",
      fecha: "fecha",
      hora: "hora",
      location: "ubicación",
      inscripcion: "inscripción",
    },
    en: {
      name: "conference",
      speakers: "speaker",
      fecha: "date",
      hora: "time",
      location: "location",
      inscripcion: "subscription",
    },
  };

  // Selecciona el conjunto de traducciones basado en `lang`
  const translations = columnTranslations[lang] || columnTranslations["es"];

  const _presentations = presentations.map((presentation) => {
    const RenderButton = presentation.active || areAllMine;
    return {
      [translations.name]: getTranslation(presentation, "name", lang),
      [translations.speakers]: presentation.speakers
        .map((speaker) => speaker.name)
        .join(", "),
      [translations.fecha]: new Date(
        presentation.datetime
      ).toLocaleDateString(),
      [translations.hora]: new Date(presentation.datetime)
        .toLocaleTimeString(undefined, { hour12: false })
        .replace(/:..$/, ""),
      [translations.location]: presentation.virtualLink ? (
        <a
          key={presentation._id}
          href={presentation.virtualLink}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          {t("app_virtual_link")}
        </a>
      ) : (
        presentation.onCampusLocation.name
      ),
      [translations.inscripcion]: (
        <ButtonInscription
          key={presentation._id}
          presentation={presentation}
          myPresentations={myPresentations}
          onSubscribe={onSubscribe}
          active={RenderButton}
        />
      ),
    };
  });

  columns =
    columns.length > 0
      ? columns
      : _presentations.length > 0
      ? Object.keys(_presentations[0])
      : [
          translations.name,
          translations.speakers,
          translations.fecha,
          translations.hora,
          translations.location,
          translations.inscripcion,
        ];

  return (
    <GenericTableWithPagination
      data={_presentations}
      columns={columns}
      itemsPerPage={itemsPerPage}
      searchColumn={searchColumn}
      searchTerm={searchTerm}
      onSearchColumnChange={setSearchColumn}
      onSearchTermChange={setSearchTerm}
      sortableColumns={columns}
      filterableColumns={columns.slice(0, -1)}
    />
  );
};

export default PresentationTable;
