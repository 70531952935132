/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { QrReader } from "react-qr-reader";
import { useLocation, Navigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Input } from "reactstrap";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import BasePage from "../../../BasePage/BasePage";


const AttendReaderPage = () => {
    const [showCamera, setShowCamera] = useState(true);
    const [identifier, setIdentifier] = useState("");
    const [attendance, setAttendance] = useState(null);

    const [openSnack] = useSnackbar();
    const [openSnackErr] = useSnackbar({ style: { backgroundColor: 'crimson' } });
    const { startLoading, stopLoading } = useConfigContext();

    const { state } = useLocation();

    useEffect(() => {
        const fetchPresentation = async () => {
            if(!state) return;

            startLoading("fetch-presentation-attend");

            try {
                const { data } = await axios.get(`/presentation/one/${state._id}`);
                setAttendance(data.attendance)
            } catch (error) {
                openSnackErr("Ocurrió un error al cargar ")
            } finally {
                stopLoading("fetch-presentation-attend")
            }
        }

        fetchPresentation();
    }, []);

    if(!state) {
        return (
            <Navigate to={"/app/attend/list"} />
        );
    }

    const onResult = (result, error) => {
        if (result && result.text) {
            setIdentifier(oldIdentifier => {
                if (result.text !== oldIdentifier) {
                    return result.text
                }

                return oldIdentifier;
            });
        }
    }

    const onSubmit = async () => {
        try {
            const { data } = await axios.post("/user/attendance", {
                presentationId: state._id,
                identifier
            });
            setAttendance(data.attended);
            openSnack("Registro de asistencia exitoso");
        } catch (error) {
            openSnackErr("Ocurrió un error al tomar asistencia")
        }
    }

    let color = "border-conia-light";
    if(attendance) {
        const ratio = attendance / state.onCampusLocation.capacity;
        if(ratio < 0.75) {
            color = "border-green-600";
        } else if (ratio >= 0.7 && ratio < 1.1) {
            color = "border-orange-600";
        } else {
            color = "border-rose-600"
        }
    }

    return (
        <BasePage center>
            <div className="p-4 w-full relative max-w-5xl flex flex-col items-center gap-4">
                    <div className="w-[350px] h-[350px] relative overflow-hidden rounded-xl bg-white">
                        
                        {
                            showCamera &&
                                <QrReader 
                                    onResult={onResult} 
                                    scanDelay={1000} 
                                    constraints={{aspectRatio: {exact: 1}, facingMode: "environment"}}/> 
                        }
                        <div className={ `absolute inset-16 border-4 ${color} border-solid rounded-xl` } style={{boxShadow: "0 0 999999px black"}} />
                        <button onClick={()=> setShowCamera(!showCamera)}
                            className={`absolute right-2 top-2 w-8 h-8 ${showCamera ? "bg-rose-700":"bg-green-700"} text-conia-light rounded-lg flex items-center justify-center`} >
                            <FaCamera/>
                        </button>
                    </div>
            
                <div className="flex-1 flex flex-col gap-2 w-full max-w-lg p-4">
                    <Input value={identifier} onChange={({ target })=> setIdentifier(target.value)} />
                    <button onClick={onSubmit} className="bg-conia-dark p-2 rounded-lg text-conia-light">
                        Registrar la entrada
                    </button>
                </div>
            </div>
            <div className="p-4 w-full relative max-w-5xl flex flex-col items-center">
                <h3 className="text-center">
                    { state.name[0]?.value }
                </h3>
                <p> 
                    { state.onCampusLocation.name }, { new Date(state.datetime).toLocaleDateString("es", { hour: "2-digit", minute: "2-digit" }) }  
                </p>

                <div className="mt-4 flex items-center gap-4 font-bold child:flex-1 child:text-center child:text-2xl">
                    <p > 
                        Capacidad <br/>
                        { state.onCampusLocation.capacity } 
                    </p>
                    <p> 
                        Asistencia <br/>
                        { attendance ?? "N/D" } 
                    </p>
                </div>
            </div>
        </BasePage>
    );
}

export default AttendReaderPage;