import { useTranslation } from "react-i18next";
import { FcGoogle } from 'react-icons/fc';

const GoogleButton = ({ onGoogleSignIn = () => {}}) => {
    const { t } = useTranslation();

    return (
        <button
            className="flex justify-center gap-2 align-middle py-2 bg-white rounded-md shadow-md font-semibold hover:bg-neutral-50"
            type="button"
            onClick={() => onGoogleSignIn()}
        >
            <FcGoogle className="translate-y-[1.5px]" /> {t("auth_form_gsi_btn")}
        </button>
    )
};

export default GoogleButton;
