import { useEffect, useState } from "react";
import { useInfoContext } from "../../../../contexts/InfoContext";
import {
  getTranslation,
  mapToLanguages,
} from "../../../../services/translation";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "react-simple-snackbar";
import PresentationTable from "../../CareerPage/PresentationTable/PresentationTable";
import { useUserContext } from "../../../../contexts/UserContext";
import { BsTable } from "react-icons/bs";
import { LuLayoutGrid } from "react-icons/lu";

const EVENT_ID = process.env.REACT_APP_EVENT_ID;

const Careers = () => {
  const { careers } = useInfoContext();
  const { startLoading, stopLoading, lang } = useConfigContext();
  const { t } = useTranslation();
  const [presentation, setPresentation] = useState([]);
  const [myPresentations, setMyPresentations] = useState([]);
  const [openSnack] = useSnackbar();
  const { token } = useUserContext();
  const navigate = useNavigate();
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    const fetchCareerInfo = async () => {
      try {
        const { data: response } = await axios.get(`/presentation/${EVENT_ID}`);
        const { data } = response;
        const _presentation = data.map((pres) => {
          const formatpres = mapToLanguages(pres, "name", "description");
          return {
            ...formatpres,
            // datetime: pres.sort((p1, p2) =>
            //   p1.datetime.localeCompare(p2.datetime)
            // ),
            virtual: pres.virtualLink ? true : false,
          };
        });
        if (token) {
          const { data: rawMine } = await axios.get(
            `/user/presentation/${EVENT_ID}`
          );
          const { data: mineList } = rawMine.virtualList;
          const { data: mineListOnCampus } = rawMine.onCampusList;
          setMyPresentations([
            ...mineList.map((p) => {
              return p._id;
            }),
            ...mineListOnCampus.map((p) => {
              return p._id;
            }),
          ]);
        }
        setPresentation(_presentation);
      } catch (error) {
        openSnack(t("snack_error_fetch"));
        console.error(error);
      } finally {
      }
    };

    fetchCareerInfo();
  }, []);

  const onSubscribe = async (presentationId) => {
    if (!token) {
      return navigate("/auth/signin", {
        state: "/",
      });
    }

    startLoading("subs-to-pres");
    const alreadySub = myPresentations.includes(presentationId);

    try {
      const virtual = presentation.find(
        (p) => p._id === presentationId
      ).virtual;
      if (!alreadySub) {
        await axios.post(`/user/presentation/${presentationId}`, {
          virtual: virtual,
        });
        setMyPresentations([...myPresentations, presentationId]);
      } else {
        await axios.delete(`/user/presentation/${presentationId}`, {
          data: {
            virtual: virtual,
          },
        });
        setMyPresentations(myPresentations.filter((p) => p !== presentationId));
      }
    } catch (error) {
      openSnack(t("snack_error_unexpected"));
    } finally {
      stopLoading("subs-to-pres");
    }
  };

  const mappedCards = careers.map((career) => (
    <Link
      className="bg-cover bg-center cursor-pointer shrink-0 w-[300px] rounded-md shadow-sm
      bg-accent hover:scale-105"
      to={`/career/${career.uri}`}
      key={`card_${career.code}`}
    >
      <div className="h-full rounded-md flex flex-col gap-2 pb-4 min-h-[100px] overflow-hidden">
        <img
          src={career.image}
          alt={getTranslation(career, "name", lang)}
          className="w-[300px] min-h-[225px] object-cover"
        />
        <h3 className="text-center text-secondary font-bold text-lg">
          {getTranslation(career, "name", lang)}
        </h3>
      </div>
    </Link>
  ));

  return (
    <section className="flex flex-col items-center gap-4 text-3xl max-w-[1400px] mx-2 lg:mx-16">
      <div className="flex flex-col justify-center items-center gap-4 w-full">
        <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
          {t("index_conferences_text")}
        </h2>
        <button onClick={() => setShowCards(!showCards)} className="text-lg">
          {showCards ? (
            <LuLayoutGrid size={25} className="inline" />
          ) : (
            <BsTable size={20} className="inline" />
          )}{" "}
          {t("index_change_view_text")}
        </button>
      </div>

      {/* Condicionalmente mostrar tarjetas o el buscador */}

      {showCards ? (
        <div className="text-base">
          <PresentationTable
            presentations={presentation}
            myPresentations={myPresentations}
            onSubscribe={onSubscribe}
          />
        </div>
      ) : (
        <div className="flex justify-center ali flex-wrap gap-4">
          {mappedCards}
        </div>
      )}
    </section>
  );
};

export default Careers;
