import { GoCalendar } from "react-icons/go";
import { useTranslation } from "react-i18next";
import html from "html-react-parser";
import SocialMedia from "../../../../components/SocialMedia/SocialMedia";
import HTMLReactParser from "html-react-parser";
import TimelineComponent from "../../../../components/Timeline/Timeline";
import API from "../../../../constants/API";
// Componente principal Slogan
const Slogan = ({ title = "", description = "", dates = [], lang = "es" }) => {
  const { t: translate } = useTranslation();

  return (
    <section className="flex flex-col justify-center items-center w-[85%] gap-4">
      <div className="flex flex-col items-center gap-7 w-full">
        {/* {HTMLReactParser(title)} */}
        <figure>
          <img
            className="w-full"
            src={`${API.FILE_BASEURL}/logo-conia2024.webp`}
            alt="CONIA"
          />
        </figure>
        <h3 className="flex gap-3 justify-center items-center text-md md:text-lg lg:text-xl font-bold">
          <GoCalendar /> {HTMLReactParser(translate("index_dates_text"))}
        </h3>
        <SocialMedia className="self-center text-text-xl gap-3" />
      </div>
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
        {HTMLReactParser(translate("index_timeline_text"))}
      </h2>
      <div className="mx-auto gap-4 sm:w-4/5 lg:w-full xl:w-[1024px]">
        <TimelineComponent dates={dates} lang={lang} />
      </div>
    </section>
  );
};

export default Slogan;
