import { useState, useMemo, useEffect } from "react";
import Table from "./Table";
import API from "../../constants/API";

const GenericTableWithPagination = ({
  data = [],
  columns = [],
  itemsPerPage = parseInt(API.ITEMS_FOR_PAGE),
  searchColumn = "",
  searchTerm = "",
  onSearchColumnChange = () => {},
  onSearchTermChange = () => {},
  sortableColumns = [],
  filterableColumns = [],
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Ordenar datos basados en la configuración de ordenamiento
  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sorted;
  }, [data, sortConfig]);

  return (
    <div className="mt-4">
      <Table
        columns={columns}
        data={sortedData}
        searchColumn={searchColumn}
        setSearchColumn={onSearchColumnChange}
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        itemsPerPage={itemsPerPage}
        handleSort={(col) =>
          setSortConfig({
            key: col,
            direction: sortConfig.direction === "asc" ? "desc" : "asc",
          })
        }
        sortConfig={sortConfig}
        sortableColumns={sortableColumns}
        filterableColumns={filterableColumns}
      />
    </div>
  );
};

export default GenericTableWithPagination;
