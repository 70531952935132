/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup, Input, Label, FormText } from 'reactstrap';

import { useState, useEffect } from 'react';

const InputList = ({values=[], onAdd=()=>{}, onRemove=()=>{}, className="", label="", id="", name="", ...rest}) => {
    const [value, setValue] = useState("");

    const onAddItem = (e) => {
        setValue(e.target.value);
        onAdd(e.target.value);
    }

    useEffect(() => {
        if (values.join(" ") !== value) {
            setValue(values.join(" "));
        }
    }, [values]);
    
    return (
        <FormGroup className={`${className}`} {...rest}>
            <Label for={id} className='font-semibold'>
                { label }
            </Label>
            <div className='flex gap-2'>
                <Input className='flex-1 min-h-10 px-4 rounded-md border-2'
                    id={id}
                    name={name} 
                    value={value}
                    onKeyUp={(e)=> { (e.key === "Enter") && onAddItem(e) }}
                    onBlur= {(e)=> { onAddItem(e) }}
                    onChange={onAddItem}/>
            </div>
        </FormGroup>
    );
}

export default InputList;