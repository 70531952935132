import { useEffect, useState } from "react";
import { MdDone, MdArrowRightAlt } from "react-icons/md";
import { getTranslation } from "../../services/translation";
import HTMLReactParser from "html-react-parser";

const TimelineComponent = ({ lang, dates }) => {
  // Función para obtener la fecha formateada
  const formatDate = (date, lang = "es") => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(date).toLocaleDateString(lang, options);
  };

  // Función para comparar fechas
  const isPastDate = (date) => {
    return new Date(date) < new Date();
  };

  // Componente para mostrar el icono de la fecha
  const IconDate = ({ isValid, current, includeLine }) => {
    const baseClasses = "absolute block p-1 rounded-full top-0";
    const validatedBgColor = "bg-accent-secondary";
    const defaultBgColor = current ? "bg-accent" : "bg-neutral-400";

    const iconClasses = "text-white";

    return (
      <>
        <span
          className={`${baseClasses} ${
            isValid ? validatedBgColor : defaultBgColor
          }`}
        >
          {isValid ? (
            <MdDone className={iconClasses} />
          ) : (
            <MdArrowRightAlt className={iconClasses} />
          )}
        </span>
        {includeLine && (
          <div
            className={`absolute h-[90%] w-[2px] top-7 z-0 ${
              isValid ? validatedBgColor : defaultBgColor
            }`}
          ></div>
        )}
      </>
    );
  };

  const calculateClosestDate = (dates) => {
    return dates.reduce((acc, date) => {
      const currentDate = new Date();
      const dateValue = new Date(getTranslation(date, "date", lang));
      const accValue = new Date(getTranslation(acc, "date", lang));

      // Calcula la diferencia absoluta en milisegundos entre la fecha actual y cada fecha
      const diffDate = Math.abs(dateValue - currentDate);
      const diffAcc = Math.abs(accValue - currentDate);

      // Retorna la fecha con la diferencia más pequeña
      return diffDate < diffAcc ? date : acc;
    });
  };

  const renderDateItems = (dates) => {
    const nextDate = calculateClosestDate(dates);
    return dates.map((date, index) => {
      const isValid = isPastDate(getTranslation(date, "date", lang));

      return (
        <li key={index} className="flex justify-start items-start">
          <div className="relative flex justify-center flex-col items-center self-stretch mr-5">
            <IconDate
              isValid={isValid}
              current={nextDate == date}
              includeLine={index !== dates.length - 1}
            />
          </div>
          <div className="flex flex-col gap-1 justify-center">
            <h3 className="flex items-center font-semibold">
              {getTranslation(date, "title", lang)}
            </h3>
            <time className="text-lg font-normal leading-none text-opacity-50">
              {formatDate(getTranslation(date, "date", lang))}
            </time>
            <div className="text-xl font-normal text-opacity-40">
              {HTMLReactParser(getTranslation(date, "description", lang))}
            </div>
          </div>
        </li>
      );
    });
  };

  const [dateElements, setDateElements] = useState([]);
  useEffect(() => {
    if (dates.length > 0) {
      const formattedDates = renderDateItems(dates);
      setDateElements(formattedDates);
    }
  }, [lang, dates]);
  return <ol className="flex flex-col text-xl">{dateElements}</ol>;
};

export default TimelineComponent;
