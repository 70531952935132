import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import BasePage from "../../../BasePage/BasePage";
import {
  getTranslation,
  mapToLanguages,
} from "../../../../services/translation";
import { useTranslation } from "react-i18next";

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const ChooserPage = () => {
  const { t: translate } = useTranslation();
  const [presentations, setPresentations] = useState([]);
  const { lang } = useConfigContext();

  const [openSnack] = useSnackbar();
  const { startLoading, stopLoading } = useConfigContext();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPresentations = async () => {
      startLoading("fetch-presentations-attend");
      try {
        const { data } = await axios.get(`/presentation/${EVENT_ID}`);
        data.data.sort((p1, p2) => p1.datetime.localeCompare(p2.datetime));

        setPresentations(
          data.data
            .map((p) => mapToLanguages(p, "name"))
            .map((p) => {
              return {
                ...p,
                virtual: p.virtualLink ? true : false,
              };
            })
        );
      } catch (error) {
        openSnack("Ocurrió un error");
        console.log(error);
        setPresentations([]);
      } finally {
        stopLoading("fetch-presentations-attend");
      }
    };

    fetchPresentations();
  }, []);

  const onChoose = (presentation) => {
    navigate("/app/attend/reader", { state: presentation });
  };

  const onObtainQr = (presentation) => {
    navigate("/app/attend/qr", { state: presentation });
  }

  return (
    <BasePage center stretched>
      <div className="w-full max-w-4xl p-4 flex flex-col gap-1">
        <h3>{translate("list_conference_text")}</h3>
        <div className="bg-primary w-full h-[2px] my-2" />

        <div className="flex flex-col">
          {presentations.length > 0 ? (
            presentations.map((p) => (
              <div key={p._id} className="p-2">
                <div className="w-full gap-3 flex items-center justify-center flex-wrap">
                  <p className="font-bold">
                    {new Date(p.datetime).toLocaleDateString("es", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                  <p className="w-32 text-center font-bold"></p>
                  <p className="flex-1 min-w-full md:min-w-[200px] text-justify">
                    {getTranslation(p, "name", lang)}
                  </p>
                  <div className="flex flex-col gap-4">
                    <button
                      onClick={() => onChoose(p)}
                      className="p-2 flex-1 min-w-[200px] md:max-w-[200px] bg-conia-dark text-conia-light rounded-md"
                    >
                      {translate("choose_text")}
                    </button>
                    <button
                      onClick={() => onObtainQr(p)}
                      className="p-2 flex-1 min-w-[200px] md:max-w-[200px] bg-conia-dark text-conia-light rounded-md"
                    >
                      {translate("obtain_qr_text")}
                    </button>
                  </div>
                </div>
                <div className="h-[1px] mt-2 bg-conia-dark opacity-50" />
              </div>
            ))
          ) : (
            <p> {translate("no_ponencias")} </p>
          )}
        </div>
      </div>
    </BasePage>
  );
};

export default ChooserPage;
