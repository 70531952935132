import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import { getTranslation, mapToLanguages } from "../../../services/translation";
import { useConfigContext } from "../../../contexts/ConfigContext";

const AttendantSelf = () => {
  const { t } = useTranslation();
  const { token } = useUserContext();
  const { lang } = useConfigContext();
  const [openSnack] = useSnackbar({});
  const navigate = useNavigate();
  const { presentationId } = useParams();
  const enviado = useRef(false);
  useEffect(() => {
    if (presentationId && !enviado.current) {
      enviado.current = true;
      axios
        .post("user/attendance/self-scan", {
          token: presentationId,
        })
        .then((response) => {
          if (response.data) {
            let { data } = response;
            data = mapToLanguages(data, "presentation");
            openSnack(
              t("inscription_success", {
                conference: getTranslation(data, "presentation", lang),
              }),
              10000
            );
            navigate("/app/my-assistances");
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            openSnack(t("inscription_fail_409"), 10000);
          } else if (error.response.status === 404) {
            openSnack(t("inscription_fail_404"), 10000);
          } else {
            openSnack(t("snack_error_unexpected"), 10000);
          }
          navigate("/app/my-assistances");
        });
    }
  }, []);

  return null;
};

export default AttendantSelf;
