import buho_fia from "../../assets/img/logo_login.png";

import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import { MdTranslate } from 'react-icons/md';

import SignUpForm from "./SignupForm/SignUpForm";
import SignInForm from "./SignInForm/SignInForm";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import PasswordRecoveryForm from "./PasswordRecoveryForm/PasswordRecoveryForm";

import { useConfigContext } from "../../contexts/ConfigContext";

import { GoogleOAuthProvider  } from "@react-oauth/google";

const GCID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

const LoginCard = ({ onSubmitSignIn = () => { }, onSubmitSignUp = () => { }, onSubmitGoogle = () => { }, onSubmitForgotPassword = () => { }, onSubmitPasswordRecovery = () => { } }) => {
    const navigate = useNavigate();
    const { toggleLang } = useConfigContext();

    return (
        <div className='w-full min-w-0 max-w-3xl min-h-screen md:min-h-0
            flex flex-col md:flex-row
            md:rounded-md bg-secondary-50 overflow-hidden shadow-md justify-center'>

            <div onClick={() => { navigate("/") }}
                className="min-w-0 p-6 max-h-[350px] md:min-h-[550px] md:p-16 md:h-auto md:flex-1  
                flex md:flex-col items-center justify-center gap-6 cursor-pointer
                overflow-hidden">

                <figure className="flex-1 flex justify-center items-center object-fill h-38 w-86 ">
                    <img className="w-full object-contain" src={buho_fia} alt="UCA" />
                </figure>
            </div>

            <div className="relative md:min-h-[500px] min-w-0 p-4 lg:flex-1 md:flex-[1.5] flex flex-col justify-center">
                <button onClick={toggleLang} 
                    className="absolute p-2 top-2 right-2 w-10 h-10 child:w-full child:h-full">
                    <MdTranslate/>
                </button>
                <Routes>
                    <Route path="/signin" element={
                        <GoogleOAuthProvider clientId={GCID}>
                            <SignInForm onSignIn={onSubmitSignIn} onSubmitGoogle={onSubmitGoogle}/>
                        </GoogleOAuthProvider >
                    } />

                    <Route path="/signup" element={
                        <GoogleOAuthProvider clientId={GCID}>
                            <SignUpForm onSignUp={onSubmitSignUp} onSubmitGoogle={onSubmitGoogle}/>
                        </GoogleOAuthProvider >
                    } />
                    <Route path="/forgot-password" element={
                        <ForgotPasswordForm onForgotPassword={onSubmitForgotPassword} />
                    } />

                    <Route path="/password-recovery" element={
                        <PasswordRecoveryForm onPasswordRecovery={onSubmitPasswordRecovery} />
                    } />

                    <Route path="*" element={<Navigate to="/not-found" />} />
                </Routes>
            </div>
        </div>
    );
}

export default LoginCard;