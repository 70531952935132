import HTMLReactParser from "html-react-parser";
import { getTranslation } from "../../../services/translation";
import html from "html-react-parser";
import { useTranslation } from "react-i18next";
import SocialMedia from "../../../components/SocialMedia/SocialMedia";

const InfoAboutComponent = ({ eventInfo, lang }) => {
  const { t: translate } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center gap-4">
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">
        {html(translate("header_about_text"))}
      </h2>
      {HTMLReactParser(getTranslation(eventInfo, "summary", lang))}
    </div>
  );
};

export default InfoAboutComponent;
