import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdArrowForward,
  MdArrowForwardIos,
} from "react-icons/md";

const Slider = ({
  children,
  slidesToShow = 3,
  className = "",
  gap = 16,
  autoPlay = false,
  autoPlayTime = 3500,
  showControls = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = React.Children.count(children);

  // Calcula cuántas "páginas" hay en el slider
  const maxIndex = Math.max(0, totalSlides - slidesToShow);

  // Función para ir al anterior conjunto de slides
  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? maxIndex : currentIndex - slidesToShow;
    setCurrentIndex(newIndex);
  };

  // Función para ir al siguiente conjunto de slides
  const nextSlide = () => {
    const isLastSlide = currentIndex >= maxIndex;
    const newIndex = isLastSlide ? 0 : currentIndex + slidesToShow;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    let interval;
    if (autoPlay) {
      interval = setInterval(() => {
        nextSlide();
      }, autoPlayTime);
    }

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [currentIndex, autoPlay, autoPlayTime]);

  return (
    <div className="flex gap-2 w-full self-center justify-center align-items">
      {showControls && (
        <button
          onClick={prevSlide}
          className="rounded-full self-center bg-gray-800 text-white p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
        >
          <MdArrowBack />
        </button>
      )}
      <div className={`overflow-hidden ${className}`}>
        <div
          className="slider-track flex h-full items-center"
          style={{
            transform: `translateX(calc(-${
              currentIndex * (100 / slidesToShow)
            }%))`,
            transition: "transform 0.5s ease-in-out",
            // width: `${(totalSlides / slidesToShow) * 100}%`, // Ajusta el ancho total de la pista según el número de slides
          }}
        >
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className="slider-item"
              style={{
                flex: `0 0 calc(${100 / slidesToShow}% - ${gap}px)`, // Ajusta el tamaño del slide
                marginRight: `${index < totalSlides - 1 ? gap + 1 : 0}px`, // Aplica margen entre los slides
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      {showControls && (
        <button
          onClick={nextSlide}
          className="rounded-full self-center bg-gray-800 text-white p-2 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
        >
          <MdArrowForward />
        </button>
      )}
    </div>
  );
};

export default Slider;
