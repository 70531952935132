import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './services/i18n';

import MainContextProvider from './contexts/MainContextProvider'; 
import LoadingSplash from './wrappers/LoadingSplash/LoadingSplash';
import SnackbarProvider from 'react-simple-snackbar';

const container = document.getElementById("root");
const root = createRoot(container);

String.prototype.capitalize = function() {
  return this.split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <MainContextProvider>
        <LoadingSplash>
          <App />
        </LoadingSplash>
      </MainContextProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
