import { Buffer } from "buffer";

const NewLine = ({ children, className }) => {
  const mappedParagraph = children.split("\\n").map(text => (
    <p key={Buffer.from(text, "base64")} className={className}>
      { text }
    </p>
  ))

  return mappedParagraph; 
} 

export default NewLine;