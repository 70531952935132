import React from "react";
import { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import { useConfigContext } from "../../../contexts/ConfigContext";
import { mapToLanguages } from "../../../services/translation";
import BasePage from "../../BasePage/BasePage";
import PresentationTable from "../../MainRouterPage/CareerPage/PresentationTable/PresentationTable";

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const MyAssistent = () => {
  const { token } = useUserContext();
  const { startLoading, stopLoading, lang } = useConfigContext();
  const [openSnack] = useSnackbar();
  const { t } = useTranslation();

  const [myPresentations, setMyPresentations] = useState([]);

  const columnTranslations = {
    es: {
      name: "conferencia",
      speakers: "ponente",
      fecha: "fecha",
      hora: "hora",
      location: "ubicación",
      inscripcion: "inscripción",
    },
    en: {
      name: "conference",
      speakers: "speaker",
      fecha: "date",
      hora: "time",
      location: "location",
      inscripcion: "subscription",
    },
  };

  useEffect(() => {
    const fetchPresentations = async () => {
      startLoading("fetch-my-presentations");
      try {
        if (token) {
          const { data: response } = await axios.get(
            `/user/attendance/${EVENT_ID}`
          );

          const { data } = response;

          data.sort((p1, p2) => p1.datetime.localeCompare(p2.datetime));
          setMyPresentations([
            ...data
              .map((p) => mapToLanguages(p, "name"))
              .map((p) => {
                return {
                  ...p,
                  virtual: p.virtualLink ? true : false,
                };
              }),
          ]);
        }
      } catch (error) {
        openSnack(t("snack_error_fetch"));
        console.log(error);
        setMyPresentations([]);
      } finally {
        stopLoading("fetch-my-presentations");
      }
    };

    fetchPresentations();
  }, [token]);

  const onSubscribe = async (presentationId) => {
    startLoading("subs-to-pres");

    try {
      await axios.delete(`/user/presentation/${presentationId}`, {
        data: {
          virtual: myPresentations.find((p) => p._id == presentationId).virtual,
        },
      });
      setMyPresentations(
        myPresentations.filter((p) => p._id !== presentationId)
      );
    } catch (error) {
      openSnack(t("snack_error_unexpected"));
    } finally {
      stopLoading("subs-to-pres");
    }
  };
  return (
    <BasePage className="md:flex-col md:items-center">
      <div className="p-4 w-full max-w-7xl">
        <h2 className="capitalize flex gap-3 items-center text-3xl ">
          {t("header_attendant_self_text")}
        </h2>

        <div className="bg-primary w-full h-[2px] my-4" />

        {myPresentations.length === 0 && (
          <h4 className="my-5 text-center">{t("my_schedule_no_set_title")}</h4>
        )}

        {myPresentations.length !== 0 && (
          <PresentationTable
            presentations={myPresentations}
            myPresentations={myPresentations.map((p) => p._id)}
            onSubscribe={onSubscribe}
            columns={[
                columnTranslations[lang].name,
                columnTranslations[lang].speakers,
                columnTranslations[lang].fecha,
                columnTranslations[lang].hora,
                columnTranslations[lang].location
            ]}
            areAllMine={true}
          />
        )}
      </div>
    </BasePage>
  );
};

export default MyAssistent;
