import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const socials = [
  {
    id: "conia-facebook-media",
    url: "https://www.facebook.com/conia.uca/",
    icon: <FaFacebook />,
    name: "Facebook",
  },
  {
    id: "conia-twitter-media",
    url: "https://twitter.com/conia_uca",
    icon: <FaTwitter />,
    name: "X",
  },
  {
    id: "conia-instagram-media",
    url: "https://www.instagram.com/conia_uca/",
    icon: <FaInstagram />,
    name: "Instagram",
  },
  {
    id: "conia-youtube-media",
    url: "https://www.youtube.com/c/CongresodeIngenieriayArquitectura",
    icon: <FaYoutube />,
    name: "Youtube",
  },
  {
    id: "conia-correo-media",
    url: "mailto:conia@uca.edu.sv",
    icon: <HiOutlineMail />,
    name: "Correo",
  },
];

const SocialMedia = ({ className = "", nameClass = "hidden", iconClass= "p-2" }) => {
  return (
    <div className={`flex ${className}`}>
      {socials.map((social) => (
        <a
          key={social.id}
          href={social.url}
          referrerPolicy="no-referrer"
          target="_blank"
          className={`flex flex-row items-center gap-2 text-center
                            border-accent-color-dark border-2 border-solid rounded-full
                            hover:text-white hover:bg-accent-secondary child:duration-[0ms]
                            text-accent-secondary text-2xl md:text-3xl lg:text-3xl shadow-sm ${iconClass}`}
          rel="noreferrer"
        >
          {social.icon}
          <p className={`${nameClass} decoration-transparent`}>{social.name}</p>
        </a>
      ))}
    </div>
  );
};

export default SocialMedia;
