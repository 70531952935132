import { getTranslation } from "../../../../services/translation";

const Sponsors = ({ items, lang }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 overflow-hidden">
      <h2 className="px-2 border-black border-solid border-b-2 text-3xl">Patrocinadores</h2>
      {items.map((item, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center gap-4"
        >
          <h2 className="text-2xl font-semibold">
            {getTranslation(item, "name", lang)}
          </h2>
          <div className="flex flex-wrap justify-center items-center gap-4 w-full">
            {item.sponsors.map((sponsor, index) => (
              <div
                key={index}
                className="flex flex-col items-center justify-center gap-4"
              >
                <a href={sponsor.url}>
                  <img
                    src={sponsor.img}
                    alt={sponsor.name}
                    style={{ width: `${sponsor.imageWidth}` }}
                  />
                </a>
                <p className="hidden">{sponsor.name}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sponsors;
