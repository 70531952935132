/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";

import { useConfigContext } from "./ConfigContext";
import { useSnackbar } from "react-simple-snackbar";
import axios from "axios";
import { mapToLanguages } from "../services/translation";
import { useTranslation } from "react-i18next";

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const ConfigContext = React.createContext();

export const InfoProvider = (props) => {
    const { startLoading, stopLoading } = useConfigContext();
    const [openSnack] = useSnackbar();
    const { t } = useTranslation();

    const [careers, setCareers] = useState([]);
    
    useEffect(() => {
        const fetchCareersInfo = async () => {
            try {
                startLoading("fetch-careers-info");

                const { data } = await axios.get(`/info/event/themes/${EVENT_ID}`);
                const themes = data.data.map(rawTheme => mapToLanguages(rawTheme, "name", "description"));

                setCareers(themes);

            } catch (error) {
                openSnack(t("snack_error_fetch"));
            }
            finally {
                stopLoading("fetch-careers-info");
            }
        }

        fetchCareersInfo();
    }, [])

    const state = useMemo(()=> ({
        careers    
    }), [careers]);

    return <ConfigContext.Provider value={state} {...props} />
}

export const useInfoContext = () => {
    const context = React.useContext(ConfigContext);

    if(!context) {
        throw new Error("useConfigContext must be call inside of a ConfigContextProvider component");
    }

    return context;
}