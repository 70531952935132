import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa6";

const TableHeader = ({ columns, handleSort, sortConfig, sortableColumns }) => {
  const getSortIcon = (col) => {
    if (sortConfig.key === col) {
      return sortConfig.direction === "asc" ? (
        <FaSortUp className="inline-block mr-2" />
      ) : (
        <FaSortDown className="inline-block mr-2" />
      );
    }
    // Iconos por defecto (Sort)
    return <FaSort className="inline-block mr-2" />;
  };

  return (
    <thead className="bg-primary-800 text-white py-4">
      <tr>
        {columns.map((col) => (
          <th
            key={col}
            scope="col"
            className={`px-4 py-4 capitalize ${
              sortableColumns.includes(col) ? "cursor-pointer" : ""
            }`}
            onClick={() => sortableColumns.includes(col) && handleSort(col)}
          >
            <div className="flex flex-row items-center gap-1">
              <span>{col}</span>
              {sortableColumns.includes(col) && getSortIcon(col)}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
