import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { useConfigContext } from "../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import API from "../../constants/API";
import { MdMenu } from "react-icons/md";
import EjesTematicos from "../EjesTematicos/EjesTematicos";
import LanguageButton from "./LanguageButton";
import AuthButtons from "./AuthButtons";
import MenuOption from "./MenuOption";

const Header = ({ pages = [], concatPath = "", eventID, isLogin = false }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Menú móvil
  const { token, logout } = useUserContext();
  const { startLoading, stopLoading, setLang, lang } =
    useConfigContext();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const menuRef = useRef(null); // Referencia para detectar clics fuera
  const buttonRef = useRef(null); // Referencia para los botones de menú

  const logo_conia = `${API.FILE_BASEURL}/logos/${eventID}.webp`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Cierra el menú si se hace clic fuera de los menús y botones
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setActiveMenu(null);
        setIsOpen(false); // Cierra el menú móvil también
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onLogout = (e) => {
    e.preventDefault();
    startLoading("logging-out");
    logout();
    navigate("/");
    stopLoading("logging-out");
  };

  const linkGeneration = (pagesToMap) => {
    return pagesToMap.map((page) => {
      if (page.loginReq && !token) return null;
      return page.pages ? (
        <EjesTematicos
          key={`nav-${page.id}`}
          page={page}
          activeMenu={activeMenu}
          setActiveMenu={(value) => {
            setActiveMenu(value);
          }}
          optionsGeneration={(subPage) => (
            <MenuOption
              key={subPage.id}
              page={subPage}
              concatPath={concatPath}
              setActiveMenu={(value) => {
                setActiveMenu(value);
                setIsOpen(false);
              }}
            />
          )}
        />
      ) : (
        <MenuOption
          key={page.id}
          page={page}
          concatPath={concatPath}
          setActiveMenu={(value) => {
            setActiveMenu(value);
            setIsOpen(false);
          }}
        />
      );
    });
  };

  return (
    <nav
      ref={menuRef}
      className="drop-shadow-sm bg-nav-color z-50 flex items-center w-full font-sans gap-2"
    >
      <h1 className="hidden">XII CONIA 2024</h1>
      <div
        className={`flex ${
          isOpen ? "flex-col lg:flex-row" : "flex-row"
        } items-center w-full ml-4 mr-4`}
      >
        <div className="flex justify-between w-full lg:w-max lg:justify-center">
          <Link to="/">
            <img className="h-10 m-3" src={logo_conia} alt={`${eventID}`} />
          </Link>
          <button
            ref={buttonRef}
            onClick={() => setIsOpen(!isOpen)} // Control del menú móvil
            className="lg:hidden text-accent-color-secondary"
          >
            <MdMenu className="text-on-nav-color" />
          </button>
        </div>

        <div
          className={`${
            isOpen ? "flex" : "hidden"
          } flex-col p-2 w-full lg:flex lg:flex-1 lg:flex-row gap-2`}
        >
          <ul className="flex flex-col justify-start lg:flex-row lg:flex-1 items-start lg:items-center m-0 pl-0 gap-2">
            {linkGeneration(pages)}
          </ul>
          <ul className="flex flex-col lg:flex-row gap-4 items-start lg:items-center m-0 pl-0">
            <li className="flex flex-col gap-1">
              <LanguageButton
                lang={lang}
                setLang={setLang}
                buttonRef={buttonRef}
              />
            </li>
          </ul>

          {!isLogin && (
            <ul className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-start lg:items-center m-0 pl-0">
              <AuthButtons
                token={token}
                onLogout={onLogout}
                translate={translate}
              />
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
