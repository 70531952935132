import { DateFormatter, HourFromatter } from "./ScheduleFormatter";

export function NullValidation(info){
    const data = (info == null) ? "No disponible" : info;

    return data;
}

export function DateValidation(schedule, lang){
    return (schedule == null) ? "No disponible" : DateFormatter(schedule, lang);
}

export function HourValidation(schedule, lang){
    return (schedule == null) ? "No disponible" : HourFromatter(schedule, lang);
}