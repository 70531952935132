/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useConfigContext } from "./ConfigContext";
import { useSnackbar } from "react-simple-snackbar";
import axios from "axios";

import { useTranslation } from 'react-i18next';

import API from "../constants/API";

const TOKEN_KEY = "token_conia";

const UserContext = React.createContext();
axios.defaults.baseURL = API.BASEURL;
axios.defaults.headers.common['Content-Type'] = "application/json";

export const UserContextProvider = (props) => {
    const { t, i18n } = useTranslation();

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    const [openSnack] = useSnackbar({  });
    const { startLoading, stopLoading } = useConfigContext();
    
    useEffect(() => {
        verifyUser();
    }, []);

    useEffect(() => {
        if(token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } 
    }, [token]);

    const verifyUser = useCallback(() => {
        const verifyUserAsync = async () => {
            startLoading("verify-user");
            
            try {
                const tokenLS = getTokenLS();

                
                if(!tokenLS) {
                    logout();
                    stopLoading();
                    return;
                }
                
                const { data } = await axios.get(`/auth/whoami`, {
                    headers: { authorization: `Bearer ${tokenLS}` },
                });
                
                setUser(data);
                setToken(tokenLS);
                
            } catch (error) {
                logout();
                openSnack(t("snack_error_unexpected"), 1000);    
            }finally {
                stopLoading("verify-user")  
            }
        }

        verifyUserAsync();
    }, [openSnack, startLoading, stopLoading]);

    const whoami = useCallback(() => {
        const whoami = async () => {
            startLoading("whoami-verify");
            
            try {    
                const { data } = await axios.get(`/auth/whoami`);
                
                setUser(data);
                
            } catch (error) {
                logout();
                openSnack(t("snack_error_unexpected"), 1000);    
            }finally {
                stopLoading("whoami-verify")  
            }
        }

        whoami();
    }, [openSnack, startLoading, stopLoading]);

    const logout = () => {
        removeTokenLS();
        setUser(null);
        setToken(null);
    }

    const login = useCallback((...params) => {
        const loginAsync = async () => {
            startLoading("login");

            try {
                if(params.length === 0) {
                    throw new Error("Parámetros vacíos");
                }

                const body = params.length === 1 ? 
                { googleToken: params[0] } : 
                { identifier: params[0], password: params[1] }

                const { data } = await axios.post(`/auth/signin`, body);

                setToken(data.token);
                setUser(data.user);
                setTokenLS(data.token);
            } catch (error) {
                logout();
                openSnack(t("snack_error_signin_fail"));
            } finally {
                stopLoading("login")
            }
        }

        loginAsync();
    }, [openSnack, startLoading, stopLoading]);

    const register = useCallback((email, password, onSuccess) => {
        const registerAsync = async () => {
            startLoading("register")
            try {
                const body = { email, password };

                await axios.post(`/auth/signup`, body);

                onSuccess();
            } catch (error) {
                if(error.response) {
                    switch (error.response.status) {
                        case 409:
                            openSnack(t("snack_error_user_already"));
                            break;
                        case 400:
                            openSnack(t("snack_error_wrong_fields"));
                            break;
                        default:
                            openSnack(t("snack_error_unexpected"));
                            break;
                    }
                } else {
                    openSnack(t("snack_error_signup_fail"));
                }
                logout();
            } finally {
                stopLoading("register")
            }
        };

        registerAsync();
    }, [openSnack, startLoading, stopLoading]);

    const loginGoogle = useCallback((data) => {
        const loginAsync = async () => {
            startLoading("login");

            try {
                setToken(data.token);
                setUser(data.user);
                setTokenLS(data.token);
            } catch (error) {
                logout();
                openSnack(t("snack_error_signin_google_fail"));
            } finally {
                stopLoading("login")
            }
        }

        loginAsync();
    }, [openSnack, startLoading, stopLoading]);

    const onForgotPassword = useCallback((identifier, onSuccess) => {
        const forgotPasswordAsync = async () => {
            startLoading("forgot-password");
            try {
                const body = { identifier, language: i18n.language };
                await axios.post("/auth/forgot-password", body);
                onSuccess();
            } catch (error) {
                openSnack(t("snack_error_unexpected"));
            } finally {
                stopLoading("forgot-password");
            }
        }

        forgotPasswordAsync();
    }, [openSnack, startLoading, stopLoading]);
    
    const onPasswordRecovery = useCallback((token, password, onSuccess) => {
        const passwordRecoveryAsync = async () => {
            startLoading("password-recovery");
            try {
                const body = { token, password };
                await axios.post("/auth/password-recovery", body);
                onSuccess();
            } catch (error) {
                openSnack(t("snack_error_unexpected"));
            } finally {
                stopLoading("password-recovery");
            }
        }

        passwordRecoveryAsync();
    }, [openSnack, startLoading, stopLoading]);

    const value = useMemo(() => ({
        user,
        token,
        logout,
        login,
        loginGoogle,
        onForgotPassword,
        onPasswordRecovery,
        whoami,
        register
    }), [token, user, login, whoami, register]);

    return <UserContext.Provider value={value} {...props}/>
}

export const useUserContext = () => {
    const context = React.useContext(UserContext);

    if(!context) {
        throw new Error("useUserContext must be call inside of a UserContextProvider component");
    }

    return context;
}

const getTokenLS = () => localStorage.getItem(TOKEN_KEY) || "";
const setTokenLS = (token) => {token && localStorage.setItem(TOKEN_KEY, token)};
const removeTokenLS = () => { localStorage.removeItem(TOKEN_KEY) };