import React from "react";
import { useTranslation } from "react-i18next";
import HTMLReactParser from "html-react-parser";

const CheckTerms = ({ checked, onChange }) => {
  const { t } = useTranslation();
  const linkTags = {
    initial_tag: "<a href='https://conia.uca.edu.sv/terms_conditions.html' className='text-blue-700 visited:text-purple-700' target='_blank'>",
    second_tag: "<a href='https://conia.uca.edu.sv/privacy_policy.html' className='text-blue-700 visited:text-purple-700' target='_blank'>",
    close_tag: "</a>"
  }

  return(
    <div className="flex gap-4 p-1 justify-center items-center relative">
      <input
        type="checkbox"
        className="checked:before:content-['✔'] pt-px scale-110 text-white duration-200 bg-white hover:bg-stone-200 flex justify-center appearance-none h-5 w-8 border-2 border-gray-800 rounded-sm transition checked:bg-green-700 checked:hover:bg-green-600 cursor-pointer"
        onChange={() => onChange(checked)}
      />
      <span>
        { HTMLReactParser(t("terms_policies_text", {initial_tag: linkTags.initial_tag, close_tag: linkTags.close_tag, second_tag: linkTags.second_tag})) }
      </span>
    </div>
  )
}

export default CheckTerms;