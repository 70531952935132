import { ConfigProvider } from "./ConfigContext";
import { UserContextProvider } from "./UserContext";
import { InfoProvider } from "./InfoContext";

const MainContextProvider = ({ children }) => {
    return(
        <ConfigProvider>
            <UserContextProvider>
                <InfoProvider>
                    { children }
                </InfoProvider>
            </UserContextProvider>
        </ConfigProvider>
    );
}

export default MainContextProvider;