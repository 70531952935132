import React, { forwardRef } from 'react';
import QRCode from "react-qr-code";
import html from "html-react-parser";

import API from "../../../../constants/API";
import { t } from "i18next";

function InfoField({ label, value }) {
  return (
    <div className="flex gap-2">
      <span className="text-xl font-bold text-gray-600">{label}:</span>
      <span className="text-lg truncate">{value}</span>
    </div>
  );
}

const UserCard = forwardRef(({ user, className, eventID, setLogoLoaded }, ref) => {
  // const logo_conia = `${API.FILE_BASEURL}/logos/${eventID}_dark.svg`;
  const logo_conia = `${API.FILE_BASEURL}/logo-${eventID}.webp`;
  return (
    <div
      ref={ref}
      className="relative w-[300px] h-[600px] md:w-[400px] md:h-[600px] bg-white border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden"
    >
      {/* Triangular design */}
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox="0 0 300 500"
        preserveAspectRatio="none"
      >
        <polygon points="0,0 60,0 0,100" className="fill-green-500" />
        <polygon points="300,0 300,100 240,0" className="fill-black" />
        <polygon points="0,500 60,500 0,400" className="fill-black" />
        <polygon points="300,500 240,500 300,400" className="fill-green-500" />
      </svg>

      {/* Badge content */}
      <div className="relative z-10 flex flex-col items-center p-6 h-full">
        {/* Event Logo */}
        <img
          src={logo_conia}
          alt={`logo conia`}
          className="w-[250px] my-8 border-4 border-white"
          onLoad={() => setLogoLoaded(true)}
        />

        {/* QR Code */}
        <div className="mb-8">
          <QRCode value={user.email} size={128} />
        </div>

        {/* Attendee Information */}
        <div className="w-full space-y-2">
          <InfoField
            label={t("carnet_label_name")}
            value={
              `${user.names.join(" ").capitalize()} ${user.lastnames
                .join(" ")
                .capitalize()}`.trim() || "N/A"
            }
          />
          <InfoField
            label={t("carnet_label_email")}
            value={user.email || "N/A"}
          />
          <InfoField
            label={t("carnet_label_institution")}
            value={user.institution || "N/A"}
          />
        </div>

        {/* Instructions */}
        <div className="mt-auto text-sm text-center w-[225px] md:w-[300px]">
          <h2 className="font-semibold mb-1">
            {t("carnet_label_instruction_title")}:
          </h2>
          <div>{html(t("carnet_label_instruction_text"))}</div>
        </div>
      </div>
    </div>
  );
});

export default UserCard;
