import { useConfigContext } from "../../contexts/ConfigContext";
import classes from './LoadingSplash.module.css';

const LoadingSplash = ({children}) => {
    const { loading } = useConfigContext();

    return (
        <>
            {children}
            {loading &&
                <div className={`fixed inset-0 bg-backdrop flex justify-center items-center z-[100]`}>
                    <div className={`${classes["lds-grid"]}`} ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            }
        </>
    );
}

export default LoadingSplash;