import { useTranslation } from "react-i18next";

const ButtonInscription = ({
  presentation,
  myPresentations,
  onSubscribe,
  active,
  className = "px-4 py-2 text-sm font-medium text-white rounded-lg"
}) => {
  const { t } = useTranslation();
  return (
    <button
      key={presentation._id}
      className={`${className} ${
        active
          ? "bg-accent": "bg-gray-300"
      }`}
      type="button"
      onClick={() => {
        if (active) onSubscribe(presentation._id);
      }}
      disabled={!active}
    >
      {active
        ? myPresentations.includes(presentation._id)
          ? t("app_unsubscribe_btn")
          : t("app_subscribe_btn")
        : t("conference_full_text")}
    </button>
  );
};

export default ButtonInscription;
