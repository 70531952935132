import { useTranslation } from "react-i18next";
import BasePage from "../BasePage/BasePage";
import InfoAboutComponent from "./Info/Info";
import TimelineAboutComponent from "./Timeline/Timeline";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

const AboutPage = ({ eventInfo, dates, lang }) => {
  return (
    <BasePage className="!p-0 md:flex-col child:p-8 child:flex child:justify-center child:w-full">
      <section className="flex flex-col justify-center items-center w-full mx-auto gap-4 sm:w-3/4 lg:w-3/4 xl:w-3/5">
        <InfoAboutComponent eventInfo={eventInfo} lang={lang} />
        <TimelineAboutComponent dates={dates} lang={lang} />
        <SocialMedia className="self-center text-text-xl gap-3" />
      </section>
    </BasePage>
  );
};

export default AboutPage;
