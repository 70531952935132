import { useCallback, useEffect, useRef, useState } from "react";
import UserCard from "./UserCard.js"; // Componente de la tarjeta del usuario
import SubscriptionButton from "./SubscriptionButton"; // Componente del botón de suscripción
import BasePage from "../../../BasePage/BasePage";
import { useUserContext } from "../../../../contexts/UserContext";
import { useConfigContext } from "../../../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PresentationTable from "../../../MainRouterPage/CareerPage/PresentationTable/PresentationTable.js";
import { toJpeg } from "html-to-image"; // Importar la función de html-to-image

const EVENT_ID = process.env.REACT_APP_EVENT_ID || "conia2024";

const LandingPage = ({ onSubscribe = () => {}, myPresentations = [] }) => {
  const { user } = useUserContext();
  const { startLoading, stopLoading, lang } = useConfigContext();
  const { t } = useTranslation();
  const [isSubscribed, setSubscribe] = useState(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const cardRef = useRef(null); // Crear una referencia para el UserCard
  useEffect(() => {
    verifyUserSubs();
  }, [user]);

  const verifyUserSubs = useCallback(() => {
    const verifySubscribed = async () => {
      if (user) {
        setSubscribe(await amISubs());
      }
    };
    verifySubscribed();
  }, [user]);

  const amISubs = async () => {
    let response = null;
    startLoading("am-i-subs-verify");
    try {
      const { data } = await axios.post(`/event/am-i-subscribed`, {
        event: EVENT_ID,
      });
      response = data.status;
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading("am-i-subs-verify");
      return response;
    }
  };

  // Función para descargar UserCard como imagen
  const downloadUserCardAsImage = useCallback(() => {
    if (cardRef.current === null) {
      return;
    }

    if (!logoLoaded) {
      console.warn("La imagen del logo aún no ha sido cargada");
      return;
    }

    toJpeg(cardRef.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "user-card.jpeg";
        link.click();
      })
      .catch((error) => {
        console.error("No se pudo generar la imagen: ", error);
      });
  }, [cardRef, logoLoaded]);

  return (
    <BasePage className="md:!p-4 text-black">
      {user && (
        <div className="flex flex-col lg:flex-row lg:justify-center items-center gap-8 my-4 w-full">
          {/* Componente para mostrar la tarjeta del usuario */}
          <div>
            {/* Usar la referencia en UserCard */}
            <UserCard
              ref={cardRef}
              user={user}
              className={"max-w-[300px] h-[500px]"}
              eventID={EVENT_ID}
              setLogoLoaded={setLogoLoaded}
            />
            {/* Botón para descargar la tarjeta como imagen */}
            <button
              onClick={downloadUserCardAsImage}
              className="mt-4 p-4 bg-accent text-black mx-auto block rounded-md"
            >
              {t("Descargar tarjeta como imagen")}
            </button>
          </div>

          <div className="p-4 overflow-x-auto w-full">
            <h2 className="capitalize flex gap-3 items-center text-3xl ">
              {t("header_my_schedule_text")}
            </h2>
            <p className="mt-2">{t("my_schedule_subtitle")}</p>

            <div className="bg-primary h-[2px] my-4" />
            <PresentationTable
              presentations={myPresentations}
              myPresentations={myPresentations.map((p) => p._id)}
              onSubscribe={onSubscribe}
              areAllMine={true}
            />
          </div>
        </div>
      )}
    </BasePage>
  );
};

export default LandingPage;
