import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaSortDown } from "react-icons/fa";
import { MdClear } from "react-icons/md";

const TableSearch = ({
  columns,
  searchTerm,
  setSearchTerm,
  filterColumns,
  setFilterColumns,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Referencia para el dropdown
  const { t: translate } = useTranslation();

  // Toggle para abrir/cerrar el menú
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Manejar selección/deselección de columnas
  const handleSelectColumn = (col) => {
    if (filterColumns.includes(col)) {
      setFilterColumns(filterColumns.filter((c) => c !== col));
    } else {
      setFilterColumns([...filterColumns, col]);
    }
  };

  // Cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Cerrar el dropdown si se hace clic fuera de él
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Texto que muestra las columnas seleccionadas
  const selectedColumnsText =
    filterColumns.length > 0 ? filterColumns.join(", ") : translate("filtered_columns_text");

  return (
    <form className="row-start-1 row-end-2 col-start-1 col-end-2 w-full mx-auto space-y-2" ref={dropdownRef}>
      <div className="flex relative items-center">
        {/* Botón de dropdown */}
        <button
          id="dropdown-button"
          onClick={toggleDropdown}
          className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-white bg-primary rounded-s-lg hover:bg-accent hover:text-secondary "
          type="button"
          style={{ maxWidth: "200px" }} // Limitar el tamaño del botón
        >
          {/* Texto con clipping si es muy largo */}
          <span className="truncate capitalize" style={{ maxWidth: "150px" }}>
            {selectedColumnsText}
          </span>
          <FaSortDown className="ml-2.5 w-2.5 h-2.5" />
        </button>

        {/* Dropdown personalizado de selección múltiple con checkboxes */}
        {isDropdownOpen && (
          <div className="absolute top-full left-0 z-10 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
            <ul className="text-sm text-gray-700 overflow-hidden rounded-md">
              {columns.map((col) => (
                <li key={col} className="px-4 py-2">
                  <label className="inline-flex items-center">
                    {/* Checkbox para cada columna */}
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-primary border-gray-300 rounded"
                      checked={filterColumns.includes(col)}
                      onChange={() => handleSelectColumn(col)}
                    />
                    <span className="ml-2 capitalize">{col}</span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Campo de búsqueda */}
        <div className="relative w-full">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="search-dropdown"
            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-e-lg border"
            placeholder={`${translate("search_box_text")}...`}
          />
          <button
            type="submit"
            className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-primary rounded-e-lg hover:bg-accent"
            onClick={(e) => {
              e.preventDefault();
              setSearchTerm("");
            }}
          >
            <MdClear className="w-4 h-4" />
            <span className="sr-only">{translate("clear_action_text")}</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default TableSearch;
