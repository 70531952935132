import { Link } from "react-router-dom";
import { MdDashboard, MdEdit, MdExitToApp } from "react-icons/md";

const AuthButtons = ({ token, onLogout, translate }) => {
  return (
    <>
      <li className="flex flex-col gap-1">
        <Link className="text-on-nav-color flex justify-center items-center gap-2 no-underline hover:text-accent"
              to={token ? "/app" : "/auth/signin"}>
          {token ? <MdDashboard className="text-accent-color-secondary" /> : <MdEdit className="text-accent-color-secondary" />}
          {token ? translate("header_dashboard_text") : translate("header_signin_text")}
        </Link>
      </li>
      {token && (
        <li className="flex flex-col gap-1 lg:m-0 lg:p-0">
          <button onClick={onLogout} className="flex items-center cursor-pointer text-on-nav-color gap-2 hover:text-accent">
            <MdExitToApp className="text-accent-color-secondary" />
            {translate("header_signout_text")}
          </button>
        </li>
      )}
    </>
  );
};

export default AuthButtons;
