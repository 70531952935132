const BasePage = ({ children, center, stretched, ...rest }) => {
  let style = rest.style ?? {};
  let positionClases = "";
  positionClases = center
    ? `${positionClases} justify-center md:items-center`
    : positionClases;
  positionClases = stretched
    ? `${positionClases} md:items-stretch`
    : positionClases;

  let className = rest.className ?? "";

  return (
    <main
      className={`w-full flex-1 flex 
      flex-col md:flex-row md:!gap-2 bg-fixed bg-iconos dark:bg-iconos-dark
      bg-white ${positionClases} ${className}`}
      style={{ gap: 0, ...style }}
    >
      {children}
    </main>
  );
};

export default BasePage;
