import BasePage from "../BasePage/BasePage";

import { useTranslation } from "react-i18next"; 

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <BasePage center>
      <p> {t("not_found_text")} </p>
    </BasePage>
  );
}

export default NotFoundPage;