import { useTranslation } from "react-i18next";
import { getTranslation, mapToLanguages } from "../../services/translation";
import { useConfigContext } from "../../contexts/ConfigContext";
import {
  DateValidation,
  HourValidation,
  NullValidation,
} from "../../utils/VariableValidation";

import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import CeremonyCard from "../CeremonyCard/CeremonyCard";
import { useSnackbar } from "react-simple-snackbar";
import { useUserContext } from "../../contexts/UserContext";

const EVENT_ID = process.env.REACT_APP_EVENT_ID;

const CeremonySection = () => {
  const { startLoading, stopLoading, lang } = useConfigContext();
  const [myPresentations, setMyPresentations] = useState([]);
  const { token } = useUserContext();
  const { t: translate } = useTranslation();
  const [featuredData, setFeaturedData] = useState([]);
  const [openSnack] = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedTalk = async () => {
      try {
        let { data } = await axios.get(
          `/presentation/featured/${EVENT_ID}`
        );

        data = data.map((presentation) =>
          mapToLanguages(presentation, "name", "summary")
        );

        if (token) {
          const { data: rawMine } = await axios.get(
            `/user/presentation/${EVENT_ID}`
          );
          const { data: mineList } = rawMine.virtualList;
          const { data: mineListOnCampus } = rawMine.onCampusList;
          setMyPresentations([
            ...mineList.map((p) => mapToLanguages(p, "name")),
            ...mineListOnCampus.map((p) => mapToLanguages(p, "name")),
          ]);
        }

        setFeaturedData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFeaturedTalk();
  }, []);

  const onSubscribe = async (presentationId) => {
    if (!token) {
      return navigate("/auth/signin", {
        state: "/",
      });
    }

    startLoading("subs-to-pres");
    const alreadySub = myPresentations.includes(presentationId);

    try {
      const virtual =
        featuredData.find((p) => p._id === presentationId).virtualLink ? true : false;
      if (!alreadySub) {
        await axios.post(`/user/presentation/${presentationId}`, {
          virtual: virtual,
        });
        setMyPresentations([...myPresentations, presentationId]);
      } else {
        await axios.delete(`/user/presentation/${presentationId}`, {
          data: {
            virtual: virtual,
          },
        });
        setMyPresentations(myPresentations.filter((p) => p !== presentationId));
      }
    } catch (error) {
      openSnack(translate("snack_error_unexpected"));
    } finally {
      stopLoading("subs-to-pres");
    }
  };

  return (
    <section className="flex justify-center w-[84%] flex-col items-center gap-4">
      <h2
        className="p-2 border-black text-3xl"
        style={{ borderBottom: 1, borderStyle: "solid" }}
      >
        {translate("ceremony_section_title")}
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
        {featuredData?.map((talk, index) => (
          <CeremonyCard
            key={index}
            id={talk._id}
            date={DateValidation(talk.datetime, lang)}
            hour={HourValidation(talk.datetime, lang)}
            description={getTranslation(talk, "summary", lang)}
            ceremonyName={getTranslation(talk, "name", lang)}
            location={NullValidation(talk.onCampusLocation?.name)}
            onSubscribe={onSubscribe}
            myPresentations={myPresentations}
            presentation={talk}
          />
        ))}
      </div>
    </section>
  );
};

export default CeremonySection;
