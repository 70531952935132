import { MdTranslate } from "react-icons/md";

const LanguageButton = ({ lang, setLang, buttonRef }) => {
  const toggleLanguage = () => {
    setLang(lang === "es" ? "en" : "es");
  };

  return (
    <button
      ref={buttonRef}
      onClick={toggleLanguage}
      className="cursor-pointer text-on-nav-color flex items-center gap-2 hover:text-accent"
    >
      <MdTranslate className="text-accent-color-secondary" />
      {lang === "es" ? "English" : "Español"}
    </button>
  );
};

export default LanguageButton;
