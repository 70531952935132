const TableRow = ({ rowData, columns }) => {
    return (
      <tr>
        {columns.map((col) => (
          <td key={col} className="px-4 py-4">
            {rowData[col]}
          </td>
        ))}
      </tr>
    );
  };
  
  export default TableRow;
  